import { TUserOrganisation, TUserOrganisationMember, UserRole } from "@/types";
import { callApi } from "./api.service";

export async function getUserOrganization(): Promise<TUserOrganisation> {
  return new Promise((resolve, reject) => {
    callApi("/users/me/organization", "get", true, {})
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => reject(err));
  });
}

export async function getUserOrganizationMembers(): Promise<
  TUserOrganisationMember[]
> {
  return new Promise((resolve, reject) => {
    callApi("/api/organization/users", "get", true, {})
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => reject(err));
  });
}

export async function postOrganizationName(name: string): Promise<{
  id: number | null;
  name: string;
}> {
  return new Promise((resolve, reject) => {
    callApi("/api/management/manager/create-organization", "post", true, {
      name,
    })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((err) => reject(err));
  });
}

export async function postAddOrganizationEmails(emails: string[]) {
  return new Promise((resolve, reject) => {
    callApi("/api/management/manager/invite", "post", true, {
      emails,
    })
      .then((resp) => {
        resolve(resp?.data);
      })
      .catch((err) => reject(err));
  });
}

export async function postUpdateUserRole(data: {
  userId: number;
  role: UserRole;
}) {
  return new Promise((resolve, reject) => {
    callApi("/api/management/manager/update-role", "post", true, {
      data,
    })
      .then((resp) => {
        resolve(resp?.data);
      })
      .catch((err) => reject(err));
  });
}

export async function deleteUser(userId: number) {
  return new Promise((resolve, reject) => {
    callApi(
      `api/management/manager/users/member?userId=${userId}`,
      "delete",
      true,
      {}
    )
      .then((resp) => {
        resolve(resp?.data);
      })
      .catch((err) => reject(err));
  });
}

export async function deleteInvitation(inviteId: number) {
  return new Promise((resolve, reject) => {
    callApi(
      `api/management/manager/users/member?inviteId=${inviteId}`,
      "delete",
      true,
      {}
    )
      .then((resp) => {
        resolve(resp?.data);
      })
      .catch((err) => reject(err));
  });
}

export async function sendInvitationToken(token: string) {
  return new Promise((resolve, reject) => {
    callApi(`/api/invite?token=${token}`, "get", true, {})
      .then((resp) => {
        resolve(resp?.data);
      })
      .catch((err) => reject(err));
  });
}
