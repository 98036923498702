import { InputField } from "@/components/input-field";
import { Switch } from "@nextui-org/switch";
import { FC, useCallback, useEffect, useState } from "react";

interface Props {
  defaultValues: {
    rate: number;
    miles: number;
    deadheadMiles?: number;
    toll: number;
    fuelPrice: number;
  };
}

export const LoadCalculator: FC<Props> = ({ defaultValues }) => {
  const [deadheadSelected, setDeadheadSelected] = useState(false);
  const [miles, setMiles] = useState(0);
  const [rpm, setRpm] = useState(0);
  const [mpg, setMpg] = useState(6.8);
  const [fuelPrice, setFuelPrice] = useState(0);
  const [toll, setToll] = useState(0);
  const [rate, setRate] = useState(0);
  const [fuelCost, setFuelCost] = useState(0);

  useEffect(() => {
    const activeMiles = defaultValues.deadheadMiles || defaultValues.miles;
    setDeadheadSelected(!!defaultValues.deadheadMiles);
    setMiles(activeMiles);
    setToll(defaultValues.toll);
    setRate(defaultValues.rate);
    setRpm(+(defaultValues.rate / activeMiles).toFixed(2));
    setFuelCost(+((activeMiles / mpg) * defaultValues.fuelPrice).toFixed(2));
    setFuelPrice(defaultValues.fuelPrice);
  }, [defaultValues, mpg]);

  const setMilesIn = useCallback(
    (val: number) => {
      setMiles(val);

      setRpm(Number((rate / val).toFixed(2)));
      setFuelCost(Number(((val / mpg) * fuelPrice).toFixed(2)));
    },
    [fuelPrice, mpg, rate]
  );

  const setRpmIn = useCallback(
    (val: number) => {
      setRpm(val);
      setRate(Number((val * miles).toFixed(2)));
      setFuelCost(Number(((miles / mpg) * fuelPrice).toFixed(2)));
    },
    [fuelPrice, miles, mpg]
  );

  const setMpgIn = useCallback(
    (val: number) => {
      setMpg(val);
      setFuelCost(Number(((miles / val) * fuelPrice).toFixed(2)));
    },
    [fuelPrice, miles]
  );

  const setRateIn = useCallback(
    (val: number) => {
      setRate(val);

      setRpm(Number((val / miles).toFixed(2)));
      setFuelCost(Number(((miles / mpg) * fuelPrice).toFixed(2)));
    },
    [fuelPrice, miles, mpg]
  );

  const handleSwitch = (value: boolean) => {
    setMilesIn(value ? defaultValues.deadheadMiles || 0 : defaultValues.miles);
    setDeadheadSelected(value);
  };

  return (
    <div className="flex flex-col justify-between bg-primary rounded-xl overflow-hidden">
      <div className="flex flex-col gap-2 p-2">
        {!!defaultValues.deadheadMiles && (
          <div className="flex justify-between">
            <p className="text-white">
              Include deadhead (+{" "}
              {(defaultValues.deadheadMiles - defaultValues.miles).toFixed(2)}{" "}
              mi)
            </p>
            <Switch
              classNames={{
                wrapper: "group-data-[selected=true]:bg-brand-700",
              }}
              isSelected={deadheadSelected}
              onValueChange={handleSwitch}
              size="sm"
            ></Switch>
          </div>
        )}
        <div className="flex flex-col gap-2">
          <div className="w-full grid grid-cols-3 gap-2">
            <InputField
              label="Trip"
              min={0}
              value={miles.toString()}
              onValueChange={setMilesIn}
            />
            <InputField
              startContent="$"
              label="RPM"
              step={0.01}
              min={0}
              value={rpm.toString()}
              onValueChange={setRpmIn}
            />
            <InputField
              label="MPG"
              step={0.01}
              min={0.01}
              value={mpg.toString()}
              onValueChange={setMpgIn}
            />
            <InputField
              startContent="$"
              label="Toll"
              min={0}
              step={10}
              value={toll.toString()}
              onValueChange={setToll}
            />
            <InputField
              startContent="$"
              label="Rate"
              min={0}
              step={10}
              value={rate.toString()}
              onValueChange={setRateIn}
            />
            <InputField
              startContent="$"
              label="Fuel"
              min={0}
              step={0.01}
              value={fuelCost.toString()}
              onValueChange={setFuelCost}
            />
          </div>
        </div>
      </div>
      <div className="h-[50px] shrink-0 px-4 py-5 flex justify-between items-center bg-brand-700">
        <h4 className="font-semibold text-lg text-white">Profit</h4>

        <p className="font-semibold text-lg text-white">
          $ {(rate - fuelCost - toll).toFixed(2)}
        </p>
      </div>
    </div>
  );
};
