import { useAppDispatch, useAppSelector } from "@/redux/store";
import { DateRangeTabs } from "./DateRangeTabs";
import { DispatchersTable } from "./DispatchersTable";
import { setManagerQueriesPeriod } from "@/redux/slices/analytics-slice";
import { TAnalyticsPeriod } from "@/types";
import { Key } from "react";

export const DispatchersData = () => {
  const dispatch = useAppDispatch();

  const period = useAppSelector(
    (state) => state.analyticsReducer.managerQueriesPeriod
  );
  const loading = useAppSelector(
    (state) => state.analyticsReducer.queriesLoading
  );
  const totalDispatchers = useAppSelector(
    (state) => state.analyticsReducer.dispatchLeaderBoardData
  ).length;

  const handlePeriodChange = (value: Key) => {
    dispatch(setManagerQueriesPeriod(value as TAnalyticsPeriod));
  };

  return (
    <div className="flex gap-4 flex-col px-4 py-3 rounded-lg border border-gray-200">
      <div className="w-full flex justify-between items-center">
        <span>
          Dispatch Leader Board
          <span className="text-gray-950 text-lg ml-2">{totalDispatchers}</span>
          <span className="mx-2">|</span>
          {new Date().toLocaleDateString("us-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </span>
        <DateRangeTabs
          loading={loading}
          onSelectionChange={handlePeriodChange}
          selectedKey={period}
        />
      </div>
      <DispatchersTable />
    </div>
  );
};
