import DATLogo from "@/assets/loadboard-icons/dat.svg";
import SylectusLogo from "@/assets/loadboard-icons/sylectus.svg";
import TruckstopLogo from "@/assets/loadboard-icons/truckstop.svg";
import LoadlinkLogo from "@/assets/loadboard-icons/loadlink.svg";

export const LOAD_BOARDS = [
  {
    id: "dat-one",
    name: "DAT One",
    icon: "img/loadboards/dat-one.png",
    href: "https://one.dat.com/search-loads-ow",
  },
  {
    id: "dat-power",
    name: "DAT Power",
    icon: "img/loadboards/dat-power.png",
    href: "https://power.dat.com/search/loads",
  },
  {
    id: "truckstop",
    name: "Truckstop",
    icon: "img/loadboards/truckstop.png",
    href: "https://main.truckstop.com",
  },
  {
    id: "loadlink",
    name: "LoadLink Web",
    icon: "img/loadboards/loadlink.png",
    href: "https://web.loadlink.ca",
  },
  {
    id: "loadlink-new",
    name: "LoadLink Web4 (New)",
    icon: "img/loadboards/loadlink-new.png",
    href: "https://thelinkloadlinkweb4.loadlink.ca/home",
  },
  {
    id: "sylectus",
    name: "Sylectus",
    icon: "img/loadboards/sylectus.png",
    href: "https://www.sylectus.com",
  },
];

export const OTHER_LOAD_BOARD = {
  id: "other",
  name: "Other",
};

export const LOAD_BOARDS_LOGO_MAP: Record<string, string> = {
  dat: DATLogo,
  sylectus: SylectusLogo,
  truckstop: TruckstopLogo,
  loadlink: LoadlinkLogo,
};

export const LOAD_BOARDS_NAME_MAP: Record<string, string> = {
  dat: "DAT",
  sylectus: "Sylectus",
  truckstop: "Truckstop",
  loadlink: "LoadLink",
};

export const LOAD_BOARDS_NAME_REGEX = /\b(dat|sylectus|truckstop|loadlink)\b/gi;
