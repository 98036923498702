"use client";

import { useEffect, useState } from "react";
import ServiceModal from "./service";
import { Account } from "@/types";
import { deleteAccount, getAccounts } from "@/server/account.service";
import TableWrapper from "./table";
import ToastMessage from "@/components/toast";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { useDisclosure } from "@nextui-org/modal";
import Notification from "@/components/notification";
import { PlusIcon } from "@/components/icons";
import { Button } from "@nextui-org/button";
import { Helmet } from "react-helmet";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

const initialAccount: Account = {
  email: "",
  id: -1,
  smtpServerHost: "",
  type: "",
  verified: false,
  counts: 0,
};

const EmailPage = () => {
  const [load, setLoad] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const { isAuth } = useSelector((state: RootState) => state.authReducer.value);

  const {
    onOpen: openNotification,
    onClose: onCloseNotification,
    isOpen: isNotificationOpen,
  } = useDisclosure();
  const {
    onOpen: onServiceOpen,
    onClose: onServiceClose,
    isOpen: isServiceOpen,
  } = useDisclosure();
  const [currentAccount, setCurrentAccount] = useState<Account | null>(
    initialAccount
  );
  const { accessToken, features } = useSelector(
    (state: RootState) => state.authReducer.value
  );

  const isMultipleEmailsEnabled = features.includes("email");

  const loadAccounts = async () => {
    setLoad(true);
    try {
      const resp = await getAccounts();
      setAccounts(resp);
    } catch (error) {}
    setLoad(false);
  };

  useEffect(() => {
    loadAccounts();
  }, [isAuth]);

  const handleDelete = async (account: Account) => {
    if (
      account.verified &&
      accounts.filter((item) => item.verified === true).length <= 1
    )
      return openNotification();
    await deleteAccount(account.id);
    setAccounts(accounts.filter((item) => item.id !== account.id));
    ToastMessage("success", `Success deleting ${account.email}`);
  };

  const onVerify = (account: Account) => {
    const API_URL = process.env.REACT_APP_API_CARRIERIFY_PREFIX;

    switch (account.type) {
      case "GOOGLE":
        return (window.location.href = `${API_URL}/account/google?accessToken=${accessToken}`);
      case "OUTLOOK":
        return (window.location.href = `${API_URL}/account/outlook?accessToken=${accessToken}`);
      default:
        setCurrentAccount(account.type === "SMTP" ? account : null);
        onServiceOpen();
        break;
    }
  };

  return (
    <div className="flex-grow px-8 py-6 text-gray-900">
      <Helmet>
        <title>LoadConnect Email Address Integration</title>
      </Helmet>
      <h2>My Emails</h2>
      <p className="my-4 text-base">
        In this section, you can connect the email addresses you want to use for
        sending emails.
      </p>
      {isMultipleEmailsEnabled && (
        <Button
          color="primary"
          variant="solid"
          aria-label="add new service"
          startContent={<PlusIcon />}
          className="data-[pressed=true]:scale-100 hover:bg-primary-600"
          onPress={() => {
            onServiceOpen();
            setCurrentAccount(null);
            sendAmplitudeData(AMPLITUDE_EVENTS.dashboardNewEmailButtonClicked);
          }}
        >
          Add new Service
        </Button>
      )}
      <ServiceModal
        isOpen={isServiceOpen}
        onClose={onServiceClose}
        account={currentAccount}
        onAddService={loadAccounts}
      />
      <TableWrapper
        loading={load}
        className="mt-7"
        accounts={accounts}
        onDelete={handleDelete}
        onVerify={onVerify}
      />
      <Notification
        isOpen={isNotificationOpen}
        onClose={onCloseNotification}
        title="You can't delete this email"
        content="You will need to have at least one active email"
      />
    </div>
  );
};

export default EmailPage;
