"use client";

import { FC, useMemo, useState } from "react";
import SidebarLogo from "./sidebar-logo";
import SidebarItem from "./sidebar-item";

import { useLocation } from "react-router-dom";

import { Button, cn } from "@nextui-org/react";

import { ReactComponent as MenuIcon } from "@/assets/icons/align-icon.svg";
import { ReactComponent as HomeIcon } from "@/assets/icons/grid-icon.svg";
import { ReactComponent as MailIcon } from "@/assets/icons/mail-icon.svg";
import { ReactComponent as EditIcon } from "@/assets/icons/edit-icon.svg";
import { ReactComponent as BookmarkIcon } from "@/assets/icons/bookmark-icon.svg";
import { ReactComponent as BillingIcon } from "@/assets/icons/dollar-icon.svg";
import { ReactComponent as AnalyticsIcon } from "@/assets/icons/analytics-icon.svg";
import { ReactComponent as TeamIcon } from "@/assets/icons/team-icon.svg";
import { SidebarEmailStats } from "./sidebar-email-stats";
import { SidebarTrialStats } from "./sidebar-trial-stats";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

interface Props {
  onRedirect?: (url: string) => void;
}

const SidebarWrapper: FC<Props> = ({ onRedirect }) => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const isMapPage = location.pathname.startsWith("/loadinfo");
  const { isAuth, accountVerified } = useSelector(
    (state: RootState) => state.authReducer.value
  );

  const toggleSidebar = () => setIsOpen((prev) => !prev);

  const sidebarCn = cn(
    "sticky border-r border-gray-200 transition-all py-5 flex flex-col gap-8 overflow-hidden z-20",
    isOpen && !isMapPage ? "w-64" : "w-14"
  );

  const NAVIGATION_ITEMS = useMemo(
    () => [
      {
        label: "Dashboard",
        icon: <HomeIcon />,
        href: "/",
        visible: true,
      },
      {
        label: "Analytics",
        icon: <AnalyticsIcon />,
        href: "/analytics",
        visible: true,
      },
      {
        label: "My account",
        icon: <MailIcon />,
        href: "/email",
        withBadge: !accountVerified,
        visible: true,
      },
      {
        label: "My templates",
        icon: <EditIcon />,
        href: "/emailTemplate",
        visible: true,
      },
      {
        label: "My loads",
        icon: <BookmarkIcon />,
        href: "/load",
        visible: true,
      },
      {
        label: "My team",
        icon: <TeamIcon />,
        href: "/my-team",
        last: true,
        visible: true,
      },
      {
        label: "Billing",
        icon: <BillingIcon />,
        href: "/pricing",
        visible: true,
      },
    ],
    [accountVerified]
  );

  return (
    <aside className={sidebarCn}>
      <div className="w-full flex justify-between items-center px-3">
        <SidebarLogo onClick={toggleSidebar} isOpen={isOpen && !isMapPage} />
        <Button
          onPress={toggleSidebar}
          isIconOnly
          variant="flat"
          className={cn(
            (!isOpen || isMapPage) && "hidden",
            "text-gray-500 h-fit !w-fit !min-w-fit !bg-black/0 data-[hover=true]:!text-brand-700"
          )}
        >
          <MenuIcon />
        </Button>
      </div>

      <div className="w-full flex flex-col flex-grow">
        {NAVIGATION_ITEMS.map(
          (item) =>
            item.visible && (
              <SidebarItem
                key={item.label}
                data={item}
                isAuth={isAuth}
                collapsed={!(isOpen && !isMapPage)}
                onClick={onRedirect}
                withBadge={item.withBadge}
              />
            )
        )}
      </div>

      <div className="w-full flex flex-col gap-5 items-center px-3">
        <SidebarTrialStats isOpen={isOpen && !isMapPage} />
        <SidebarEmailStats isOpen={isOpen && !isMapPage} />
      </div>
    </aside>
  );
};

export default SidebarWrapper;
