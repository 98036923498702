"use client";

import { EditIcon, TrashIcon } from "@/components/icons";
import { Template } from "@/types";
import { Button } from "@nextui-org/button";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";
import DeleteButton from "@/components/delete-button";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "@nextui-org/react";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { Key, useMemo } from "react";

const TemplateList = ({
  templates,
  onDelete,
  loading,
  ...props
}: {
  templates: Template[];
  loading: boolean;
  onDelete: Function;
  className?: string;
  props?: any;
}) => {
  const navigate = useNavigate();
  const features = useSelector(
    (state: RootState) => state.authReducer.value.features
  );

  const isCCEnabled = features.includes("cc");

  const redirectToEditor = (template: Template) =>
    navigate(`/emailTemplateEditor/${template.id}`);

  const columns = useMemo(
    () => [
      { key: "id", label: "ID" },
      { key: "name", label: "Name" },
      ...(isCCEnabled ? [{ key: "cc", label: "CC" }] : []),
      { key: "subject", label: "Subject" },
      { key: "action", label: "Action" },
    ],
    [isCCEnabled]
  );

  const renderCell = (item: Template, columnKey: Key) => {
    const value = item[columnKey as keyof Template];

    switch (columnKey) {
      case "cc":
        return isCCEnabled ? value || "-" : null;
      case "action":
        return (
          <>
            <Button
              isIconOnly
              variant="light"
              as={Link}
              to={`/emailTemplateEditor/${item.id}`}
            >
              <EditIcon size={20} />
            </Button>

            <DeleteButton
              content="If you delete your template, all the content within it will be permanently lost."
              onDelete={() => onDelete && onDelete(item)}
            >
              <TrashIcon size={20} />
            </DeleteButton>
          </>
        );
      default:
        return value;
    }
  };

  return (
    <Table
      shadow="none"
      classNames={{
        wrapper: "p-0",
        tr: "cursor-pointer hover:bg-gray-100 rounded",
        td: "p-4 text-black",
        th: "p-4 text-sm text-center",
      }}
      aria-label="Template List"
      {...props}
    >
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody
        isLoading={loading}
        loadingContent={<Spinner />}
        items={templates}
      >
        {(item) => (
          <TableRow key={item.id} onClick={() => redirectToEditor(item)}>
            {(columnKey) => (
              <TableCell>{renderCell(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default TemplateList;
