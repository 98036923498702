import { CircularProgress } from "@nextui-org/react";
import { FC } from "react";

interface Props {
  show: boolean;
}

export const LoadingCircle: FC<Props> = ({ show }) => {
  if (!show) return <></>;

  return (
    <div className="flex items-center justify-center absolute z-10 top-0 left-0 right-0 bottom-0 bg-white/60">
      <CircularProgress
        classNames={{
          indicator: "stroke-brand-700",
        }}
        aria-label="Loading..."
      />
    </div>
  );
};
