import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  PersistConfig,
  createTransform,
} from "redux-persist";
import { PERSIST_STORE_NAME } from "@/config/app.config";
import storage from "redux-persist/lib/storage";

import authReducer from "../slices/auth-slice";
import userInfoReducer from "../slices/user-info-slice";
import billingReducer from "../slices/billing-slice";
import analyticsReducer from "../slices/analytics-slice";
import myTeamReducer from "../slices/my-team-slice";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "@/types";

const setTransform = createTransform<InitialState, InitialState>(
  (inboundState, key) => {
    if (key === "authReducer") {
      storage
        .getItem(`${PERSIST_STORE_NAME}:authReducer`)
        .then((storedStateRaw) => {
          if (storedStateRaw) {
            const storedState: InitialState = JSON.parse(storedStateRaw);
            if (
              storedState.value.accessToken &&
              !inboundState.value.accessToken
            ) {
              inboundState.value.accessToken = storedState.value.accessToken;
            }
            if (
              storedState.value.refreshToken &&
              !inboundState.value.refreshToken
            ) {
              inboundState.value.refreshToken = storedState.value.refreshToken;
            }
          }
        });
    }
    return inboundState;
  },
  (outboundState) => outboundState,
  { whitelist: ["authReducer"] }
);

const persistConfig: PersistConfig<any> = {
  key: PERSIST_STORE_NAME,
  keyPrefix: "",
  storage,
  blacklist: [
    "userInfoReducer",
    "billingReducer",
    "analyticsReducer",
    "myTeamReducer",
  ],
  transforms: [setTransform],
};

export const store = configureStore({
  reducer: persistReducer(
    persistConfig,
    combineReducers({
      authReducer,
      userInfoReducer,
      billingReducer,
      analyticsReducer,
      myTeamReducer,
    })
  ),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([]),
  devTools: true,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = (selector: (state: RootState) => any) =>
  useSelector(selector);
