import { TAddOn } from "@/types";
import {
  Card,
  Button,
  Modal,
  useDisclosure,
  ModalBody,
  ModalContent,
  ModalFooter,
  cn,
  CardBody,
  Link,
} from "@nextui-org/react";
import { FC } from "react";
import { ReactComponent as SparkleIcon } from "@/assets/icons/sparkle-icon.svg";
import { useAppSelector } from "@/redux/store";
import { CONTACT_US_LINK } from "@/constants/links";

interface Props {
  isActive: boolean;
  data: TAddOn;
  onSubmit: () => void;
}

export const AddOn: FC<Props> = ({ isActive, data, onSubmit }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { name, price, description, lockedText } = data;

  const billyngType = useAppSelector((state) => state.billingReducer.type);

  const isBasic = billyngType.toLowerCase() === "basic";

  const className = cn({
    "p-3 flex flex-row items-center justify-center gap-4 rounded-xl": true,
    "bg-primary-50": !isActive,
    border: isActive,
  });

  return (
    <Card fullWidth shadow="none" radius="none">
      <CardBody className={className}>
        {isActive ? (
          <div className="flex justify-between items-center w-full">
            <div className="flex flex-col gap-1">
              <h5 className="text-gray-950 font-semibold w-fit block">
                {name}
              </h5>
              <p className="text-gray-950">{description}</p>
            </div>
            <Button
              size="sm"
              radius="lg"
              color="primary"
              variant="bordered"
              disabled
            >
              Active
            </Button>
          </div>
        ) : (
          <>
            <p className="text-brand-700 font-semibold w-fit block">
              {lockedText}
            </p>

            <Button
              radius="lg"
              startContent={<SparkleIcon />}
              color="primary"
              onPress={onOpen}
              as={isBasic ? Button : Link}
              href={isBasic ? "" : CONTACT_US_LINK}
            >
              Upgrade now
            </Button>

            <Modal
              radius="lg"
              isOpen={isOpen}
              onOpenChange={onOpenChange}
              className="rounded-xl"
            >
              <ModalContent className="py-4 px-2 pb-1">
                {(onClose) => (
                  <>
                    <ModalBody>
                      <div className="flex gap-4">
                        <img
                          className="w-7 h-7"
                          src="/img/emojis/envelope-heart-emoji.png"
                          alt="heart"
                        />
                        <h4 className="font-medium">Ready to Upgrade?</h4>
                      </div>
                      <p className="text-gray-950">
                        You'll be charged an extra <b>${price}/month</b> on your
                        current subscription.
                      </p>
                    </ModalBody>
                    <ModalFooter className="flex justify-between">
                      <Button
                        size="sm"
                        radius="lg"
                        variant="bordered"
                        as={Link}
                        href={CONTACT_US_LINK}
                        target="_blank"
                      >
                        Contact us
                      </Button>
                      <Button
                        size="sm"
                        radius="lg"
                        color="primary"
                        onPress={onSubmit}
                      >
                        Continue
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
          </>
        )}
      </CardBody>
    </Card>
  );
};
