import { useAppSelector } from "@/redux/store";
import { MyTeamFilters } from "./MyTeamFilters";
import { MyTeamTable } from "./MyTeamTable";

export const MyTeamData = () => {
  const { role } = useAppSelector((state) => state.authReducer.value);

  const isManager = role === "MANAGER";

  return (
    <div className="w-full h-full flex flex-col gap-4">
      {isManager && <MyTeamFilters />}
      <MyTeamTable />
    </div>
  );
};
