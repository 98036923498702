import { TUserOrganisationMember } from "@/types";

export const getAllMembersSelected = (membersList: TUserOrganisationMember[]) =>
  membersList
    .filter((member) => member.userId)
    .map((member) => member.userId?.toString() || "");

export const getAllMembersOptions = (
  membersList: TUserOrganisationMember[]
) => {
  const list = membersList
    .filter((member) => member.userId)
    .map((member) => ({
      id: member.userId?.toString() || "",
      name: member.email,
    }));

  return [{ id: "all", name: "All" }, ...list];
};
