import { SearchBoxSuggestion } from "@mapbox/search-js-core";
import { Button, CircularProgress, Input, cn } from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { LoadInfo_Place } from "@/types";
import { search, sessionToken } from "@/config/mapbox.config";
import AutocompleteItem from "./autocomplete-item";
import { TrashIcon } from "../icons";
import { getSearchResult } from "@/server/loadinfo.service";
import "./index.css";

const AddressAutocomplete = ({
  value,
  onChange,
  onDelete,
  className,
  onFocus,
  label,
}: {
  onChange?: any;
  className?: string;
  onDelete?: any;
  onFocus?: any;
  label?: string;
  value?: LoadInfo_Place;
}) => {
  const [suggest, setSuggest] = useState<SearchBoxSuggestion[]>([]);
  const [loading, setLoading] = useState(false);
  const [str, setStr] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setStr(value?.address || "");
    const func = async () => {
      if (!value?.coordinates && value?.address) {
        const result = await getSearchResult(value.address, sessionToken.id);
        const suggestions = result.suggestions;
        if (suggestions.length === 0) return;
        const { features } = await search.retrieve(suggestions[0], {
          sessionToken,
        });
        const data = value;
        data.coordinates = {
          lat: features[0].geometry.coordinates[1],
          long: features[0].geometry.coordinates[0],
        };
        onChange && onChange(data);
      }
    };

    func();
    // eslint-disable-next-line
  }, [value]);

  const _loadSuggests = async (value: string) => {
    const result = await getSearchResult(value, sessionToken.id);
    if (result.suggestions.length === 0) return;

    setSuggest(result.suggestions);
    setLoading(false);
  };

  // eslint-disable-next-line
  const loadSuggests = useCallback(debounce(_loadSuggests, 1000), []);

  const handleInput = async (e: any) => {
    const current = e.target.value;
    setStr(current);

    if (current.length >= 3) {
      setLoading(true);
      await loadSuggests(e.target.value);
    }
  };

  const handleClick = (data: LoadInfo_Place) => {
    setStr(data.address);
    onChange && onChange(data);
    setSuggest([]);
  };

  return (
    <div className={cn("relative", className)}>
      <Input
        classNames={{
          input: "map-input",
          clearButton: "map-delete",
          innerWrapper: "end-content",
        }}
        isClearable
        label={label}
        placeholder="Fill your destination"
        value={str}
        onChange={handleInput}
        onFocus={() => {
          setOpen(true);
          onFocus && onFocus();
        }}
        onBlur={() => setOpen(false)}
        onClear={() => setSuggest([])}
        endContent={
          loading ? (
            <CircularProgress
              isIndeterminate
              aria-label="loading"
              size="sm"
              className="py-1"
            />
          ) : (
            <Button isIconOnly variant="light" onPress={onDelete}>
              {" "}
              <TrashIcon size={18} />{" "}
            </Button>
          )
        }
      />
      <div
        className={cn(
          "overflow-hidden absolute z-40 w-full",
          suggest.length > 0 && open ? "" : "max-h-0"
        )}
      >
        <ul className=" bg-white p-2 rounded-bl rounded-br border-zinc-100 border border-t-0">
          {suggest.map((item, idx) => (
            <AutocompleteItem key={idx} value={item} onClick={handleClick} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AddressAutocomplete;
