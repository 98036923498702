import { ChartOptions } from "chart.js";
import { FC, useMemo, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { ChartTooltip } from "../chart-tooltip";
import { DoughnutChartData } from "@/types";

interface Props {
  chartData: DoughnutChartData;
}
type TooltipModel = {
  dataIndex: number;
  datasetIndex: number;
  caretX: number;
  caretY: number;
};

export const DoughnutChart: FC<Props> = ({ chartData }) => {
  const [tooltipModel, setTooltipModel] = useState<TooltipModel | null>(null);

  const options: ChartOptions<"doughnut"> = useMemo(() => {
    return {
      cutout: 90,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: function ({ tooltip }) {
            if (tooltip.opacity === 0) {
              setTooltipModel(null);
            } else {
              setTooltipModel({
                dataIndex: tooltip.dataPoints[0].dataIndex,
                datasetIndex: tooltip.dataPoints[0].datasetIndex,
                caretX: tooltip.caretX,
                caretY: tooltip.caretY,
              });
            }
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };
  }, []);

  return (
    <>
      <Doughnut data={chartData.data} options={options} />
      <ChartTooltip tooltipModel={tooltipModel} data={chartData.tooltipData} />
    </>
  );
};
