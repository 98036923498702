import { LoadboardTimeGraph } from "./components/LoadboardTimeGraph";
import { Stats } from "./components/Stats";
import { DispatchersData } from "./components/DispatchersData";
import {
  getManagerAnalyticsData,
  getManagerLoadboardTimeGraphData,
} from "@/redux/async/getAnalyticsData";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect, useMemo } from "react";
import { TUserOrganisationMember } from "@/types";
import { FitlersSelectProps } from "../filters-select";
import { setSelectedUsers } from "@/redux/slices/analytics-slice";
import { SharedSelection } from "@nextui-org/react";
import {
  getAllMembersOptions,
  getAllMembersSelected,
} from "@/utils/usersSelect";

export const ManagerAnalytics = () => {
  const dispatch = useAppDispatch();
  const period = useAppSelector((state) => state.analyticsReducer.statsPeriod);
  const userId = useAppSelector((state) => state.authReducer.value.userId);
  const selectedUsers = useAppSelector(
    (state) => state.analyticsReducer.selectedUsers
  );
  const membersList: TUserOrganisationMember[] = useAppSelector(
    (state) => state.myTeamReducer.members
  );
  const loading: boolean = useAppSelector(
    (state) => state.myTeamReducer.loading
  );

  useEffect(() => {
    if (selectedUsers.length === 0) return;
    dispatch(getManagerAnalyticsData({ period, selectedUsers }));
  }, [dispatch, period, selectedUsers]);

  useEffect(() => {
    dispatch(getManagerLoadboardTimeGraphData(selectedUsers));
  }, [dispatch, selectedUsers]);

  const userSelectOptions: FitlersSelectProps = useMemo(
    () => ({
      isLoading: loading,
      label: "User",
      options: getAllMembersOptions(membersList),
      onSelectionChange: (keys: SharedSelection) => {
        const array = Array.from(keys);
        const currentKey = keys.currentKey;
        const allPosition = array.indexOf("all");

        if (allPosition !== -1) {
          array.splice(allPosition, 1);
        }

        if (currentKey === "all") {
          dispatch(setSelectedUsers(getAllMembersSelected(membersList)));
          return;
        }

        if (
          !currentKey &&
          array.length === getAllMembersSelected(membersList).length
        ) {
          dispatch(setSelectedUsers([userId.toString()]));
          return;
        }

        dispatch(setSelectedUsers(array));
      },
      selectedKeys:
        selectedUsers.length === getAllMembersSelected(membersList).length
          ? ["all", ...selectedUsers]
          : selectedUsers,
    }),
    [dispatch, loading, membersList, selectedUsers, userId]
  );

  return (
    <>
      <div className="col-span-2">
        <Stats withSelect selectProps={userSelectOptions} />
      </div>
      <div className="col-span-2">
        <LoadboardTimeGraph />
      </div>
      <div className="col-span-2">
        <DispatchersData />
      </div>
    </>
  );
};
