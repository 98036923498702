import { DataTable } from "@/components/table";
import { Chip, ChipProps, getKeyValue } from "@nextui-org/react";
import { useCallback, useEffect, useMemo } from "react";
import { ReactComponent as StatusAcceptedIcon } from "@/assets/icons/status-accepted-icon.svg";
import { ReactComponent as StatusDeclinedIcon } from "@/assets/icons/status-declined-icon.svg";
import { ReactComponent as StatusPendingIcon } from "@/assets/icons/status-pending-icon.svg";
import { getUserOrganizationMembersList } from "@/redux/async/getMyTeamInfo";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { TUserOrganisationMember, UserInviteStatus } from "@/types";
import { MyTeamMemberActions } from "./MyTeamMemberActions";

type Column = {
  key: string;
  label: string;
  align?: "start" | "center" | "end";
  allowsSorting?: boolean;
};

type Row = {
  key: string;
  name: string;
  status: string;
  role: string;
  email: string;
};

const statusColorMap: Record<
  Lowercase<UserInviteStatus>,
  ChipProps["color"]
> = {
  accepted: "success",
  expired: "danger",
  invited: "warning",
};

const statusIconMap: Record<Lowercase<UserInviteStatus>, JSX.Element> = {
  accepted: <StatusAcceptedIcon />,
  expired: <StatusDeclinedIcon />,
  invited: <StatusPendingIcon />,
};

const columns: Column[] = [
  { key: "email", label: "Email", allowsSorting: true },
  { key: "inviteStatus", label: "Status", allowsSorting: true },
  { key: "role", label: "Role", allowsSorting: true },
  { key: "actions", label: "", allowsSorting: false },
];

export const MyTeamTable = () => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector((state) => state.authReducer.value.role);
  const userId = useAppSelector((state) => state.authReducer.value.userId);
  const membersList: TUserOrganisationMember[] = useAppSelector(
    (state) => state.myTeamReducer.members
  );
  const loading: boolean = useAppSelector(
    (state) => state.myTeamReducer.loading
  );
  const { search, status, role } = useAppSelector(
    (state) => state.myTeamReducer.filter
  );
  const isManager: boolean = userRole === "MANAGER";

  useEffect(() => {
    dispatch(getUserOrganizationMembersList());
  }, [dispatch]);

  const rows: TUserOrganisationMember[] = useMemo(() => {
    return membersList.filter((member) => {
      const isSearchMatch = member.email.includes(search);
      const isStatusMatch =
        status === "all" || member.inviteStatus.toLowerCase() === status;
      const isRoleMatch = role === "all" || member.role.toLowerCase() === role;

      return isSearchMatch && isStatusMatch && isRoleMatch;
    });
  }, [membersList, role, search, status]);

  const renderCell = useCallback(
    (row: TUserOrganisationMember, columnKey: React.Key) => {
      const cellValue = getKeyValue(row, columnKey as string);
      const isMe = row.userId === userId;

      switch (columnKey) {
        case "email":
          return (
            <div className="text-gray-950">
              {cellValue}
              {isMe && " (You)"}
            </div>
          );
        case "inviteStatus":
          const status = cellValue.toLowerCase() as Lowercase<UserInviteStatus>;
          return (
            <Chip
              variant="flat"
              size="lg"
              radius="lg"
              color={statusColorMap[status]}
              startContent={statusIconMap[status]}
            >
              {status[0].toUpperCase() + status.slice(1)}
            </Chip>
          );
        case "role":
          return (
            <p className="text-gray-950">
              {cellValue[0].toUpperCase() + cellValue.slice(1).toLowerCase()}
            </p>
          );
        case "actions":
          return (
            <div className="relative flex justify-end items-center gap-2">
              {isManager && !isMe && <MyTeamMemberActions user={row} />}
            </div>
          );
        default:
          return cellValue;
      }
    },
    [isManager, userId]
  );

  return (
    <DataTable
      rows={rows as unknown as Row[]}
      columns={columns}
      maxHeight="calc(100vh_-_100px)"
      renderCell={renderCell}
      emptyText="No members found"
      isLoading={loading}
    />
  );
};
