import * as amplitude from "@amplitude/analytics-browser";
import { debounce } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

const isProduction = false;

export const initAmplitude = () => {
  if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, {
      defaultTracking: {
        sessions: true,
      },
    });

    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 0.1,
    });
    amplitude.add(sessionReplayTracking);
  }
};

export const setAmplitudeUserDevice = (id?: string) => {
  const STORAGE_KEY = "amplitude-device-id";

  const getOrGenerateUUID = () => {
    if (id) return id;

    let uuid = localStorage.getItem(STORAGE_KEY);
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem(STORAGE_KEY, uuid);
    }
    return uuid;
  };

  const uuid = getOrGenerateUUID();

  if (isProduction) {
    amplitude.setDeviceId(uuid);
  } else {
    console.log("Amplitude Device ID:", uuid);
  }
};

export const setAmplitudeUserId = (userId: string) => {
  if (isProduction) {
    amplitude.setUserId(userId);
  } else {
    console.log("Amplitude User ID", userId);
  }
};

export const sendAmplitudeData = (
  eventType: string,
  eventProperties?: Record<string, any>
) => {
  if (isProduction) {
    amplitude.logEvent(eventType, eventProperties);
  } else {
    console.log("Amplitude Event", eventType, eventProperties);
  }
};

export const debounceSendAmplitudeData = debounce(
  (eventType: string, eventProperties?: Record<string, any>) => {
    sendAmplitudeData(eventType, eventProperties);
  },
  500
);
