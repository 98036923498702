import {
  Dropdown,
  DropdownTrigger,
  Button,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { ReactComponent as VerticalDotsIcon } from "@/assets/icons/vertical-dots-icon.svg";
import { TUserOrganisationMember } from "@/types";
import { FC, Key } from "react";
import {
  deleteInvitation,
  deleteUser,
  postUpdateUserRole,
} from "@/server/myTeam.service";
import ToastMessage from "@/components/toast";
import { useAppDispatch } from "@/redux/store";
import {
  getUserOrganizationMembersList,
  setUserOrganisationMembers,
} from "@/redux/async/getMyTeamInfo";

interface Props {
  user: TUserOrganisationMember;
}

const ACTION_KEYS = {
  CHANGE_STATUS: "changeStatus",
  DELETE: "delete",
  RESEND_INVITATION: "resendInvitation",
  DELETE_INVITATION: "deleteInvitation",
};

export const MyTeamMemberActions: FC<Props> = ({ user }) => {
  const dispatch = useAppDispatch();
  const { userId, inviteId, role, email } = user;
  const isManager = role === "MANAGER";

  const handleAction = (key: Key) => {
    try {
      switch (key) {
        case ACTION_KEYS.CHANGE_STATUS:
          postUpdateUserRole({
            userId: userId as number,
            role: isManager ? "USER" : "MANAGER",
          }).then(() => {
            ToastMessage(
              "success",
              `${email} is now ${isManager ? "User" : "Manager"}`
            );
            dispatch(getUserOrganizationMembersList());
          });
          break;
        case ACTION_KEYS.RESEND_INVITATION:
          dispatch(setUserOrganisationMembers([email])).then(() => {
            ToastMessage("success", `${email} invitation resent`);
            dispatch(getUserOrganizationMembersList());
          });
          break;
        case ACTION_KEYS.DELETE:
          deleteUser(userId as number).then(() => {
            ToastMessage(
              "success",
              `${email} is deleted from the organization`
            );
            dispatch(getUserOrganizationMembersList());
          });
          break;
        case ACTION_KEYS.DELETE_INVITATION:
          deleteInvitation(inviteId as number).then(() => {
            ToastMessage("success", `${email} invitation deleted`);
            dispatch(getUserOrganizationMembersList());
          });
          break;
        default:
          break;
      }
    } catch (err) {
      console.error(err);
      ToastMessage("error", "Something went wrong");
    }
  };

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button isIconOnly size="sm" variant="light">
          <VerticalDotsIcon className="text-default-300" />
        </Button>
      </DropdownTrigger>
      <DropdownMenu aria-label="User actions" onAction={handleAction}>
        {userId ? (
          <>
            <DropdownItem
              textValue={ACTION_KEYS.CHANGE_STATUS}
              key={ACTION_KEYS.CHANGE_STATUS}
            >
              Set as {isManager ? "User" : "Manager"}
            </DropdownItem>
            <DropdownItem
              className="text-danger"
              color="danger"
              textValue={ACTION_KEYS.DELETE}
              key={ACTION_KEYS.DELETE}
            >
              Delete
            </DropdownItem>
          </>
        ) : (
          <>
            <DropdownItem
              textValue={ACTION_KEYS.RESEND_INVITATION}
              key={ACTION_KEYS.RESEND_INVITATION}
            >
              Resend invitation
            </DropdownItem>
            <DropdownItem
              className="text-danger"
              color="danger"
              textValue={ACTION_KEYS.DELETE_INVITATION}
              key={ACTION_KEYS.DELETE_INVITATION}
            >
              Delete invitation
            </DropdownItem>
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
