import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { ArticleWrapper } from "@/components/article-wrapper";
import { CONTACT_US_LINK } from "@/constants/links";
import { ITutorial } from "@/types";
import {
  Button,
  Card,
  cn,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";

interface IProps {
  data: ITutorial;
}

export const Tutorial: FC<IProps> = ({ data }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const { id, title, description, video, icon, fullDescription, big } = data;

  const showModal = useCallback(() => {
    if (!isShowModal)
      sendAmplitudeData(AMPLITUDE_EVENTS.welcomePageTextTutorialClicked);
    setIsShowModal(true);
  }, [isShowModal]);

  const className = cn(
    "w-full h-full bg-white shadow-none border rounded-xl hover:shadow-md hover:border-white cursor-pointer transition-all",
    big ? "col-span-2 row-span-2 p-2" : "col-span-1 row-span-1 p-5"
  );

  const innerContent = useMemo(() => {
    if (video) {
      return (
        <iframe
          onClick={() =>
            sendAmplitudeData(AMPLITUDE_EVENTS.welcomePageVideoClicked)
          }
          width="100%"
          height="100%"
          className="rounded-lg"
          src={video}
          title={title}
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
        />
      );
    }

    return (
      <div
        id={`tutorial-${id}`}
        onClick={showModal}
        className="flex flex-col gap-2 relative"
      >
        <img width={30} height={30} alt={title} src={icon} className="mb-2" />
        <div className="flex flex-col gap-1">
          <h4 className="text-base ">{title}</h4>
          <p className="text-sm line-clamp-2">{description}</p>
        </div>
        <span className="absolute top-0 right-0 h-1 leading-[1px]">...</span>
        {
          <TutorialModal
            show={isShowModal}
            title={title}
            content={fullDescription}
            setShow={setIsShowModal}
          />
        }
      </div>
    );
  }, [
    description,
    fullDescription,
    icon,
    id,
    isShowModal,
    showModal,
    title,
    video,
  ]);

  return <Card className={className}>{innerContent}</Card>;
};

const TutorialModal: FC<{
  show: boolean;
  title: string;
  content: ReactNode;
  setShow?: (show: boolean) => void;
}> = ({ show, title, content, setShow }) => {
  const sendEmailAmplitudeEvent = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.welcomePageTutorialModalEmailClicked);
  const sendPhoneAmplitudeEvent = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.welcomePageTutorialModalPhoneClicked);
  const sendContactUsAmplitudeEvent = () =>
    sendAmplitudeData(
      AMPLITUDE_EVENTS.welcomePageTutorialModalContactUsButtonClicked
    );
  const sendCloseAmplitudeEvent = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.welcomePageTutorialModalClosed);

  return (
    <Modal
      size="2xl"
      isOpen={show}
      onOpenChange={setShow}
      className="p-0 rounded-2xl"
      scrollBehavior="inside"
      onClose={sendCloseAmplitudeEvent}
    >
      <ModalContent>
        <ModalHeader className="text-xl font-semibold text-gray-900 p-5">
          {title}
        </ModalHeader>
        <ModalBody className="p-5">
          <ArticleWrapper>{content || "In Development"}</ArticleWrapper>
        </ModalBody>
        <ModalFooter className="p-5">
          <p>
            If you encounter any issues, please contact our Support Team at{" "}
            <a
              onClick={sendEmailAmplitudeEvent}
              className="text-brand-700"
              href="mailto:hello@loadconnect.io"
            >
              hello@loadconnect.io
            </a>{" "}
            or{" "}
            <a
              onClick={sendPhoneAmplitudeEvent}
              className="text-brand-700"
              href="tel:+14156501670"
            >
              +1 (415) 650-1670
            </a>
          </p>
          <Button
            onPress={sendContactUsAmplitudeEvent}
            as={Link}
            href={CONTACT_US_LINK}
            color="primary"
            radius="lg"
            target="_blank"
          >
            Contact Us
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
