import { EllipsisIcon, PlusIcon } from "@/components/icons";
import { Button, RadioGroup } from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import DragContent from "./drag-content";
import { LoadInfo_Place } from "@/types";
import AddressAutocomplete from "@/components/address-autocomplete";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

const PointPicker = ({
  places,
  onChange,
  onFocus,
}: {
  onChange?: any;
  onFocus?: any;
  places: LoadInfo_Place[];
}) => {
  const [itemList, setItemList] = useState(places);
  const [radio, setRadio] = useState("");

  useEffect(() => {
    setItemList(places);
    setRadio(places[0] && places[0].address);
  }, [places]);

  const handleDrop = useCallback(
    (droppedItem: any) => {
      if (!droppedItem.destination) return;
      var updatedList = [...itemList];
      const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
      updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
      setItemList(updatedList);
      sendAmplitudeData(AMPLITUDE_EVENTS.loadConnectMapDnDUsed);
      onChange && onChange(updatedList);
    },
    [itemList, onChange]
  );

  const handleChange = useCallback(
    (data: LoadInfo_Place, index: number) => {
      const newData = [...places];
      newData[index] = data;

      sendAmplitudeData(AMPLITUDE_EVENTS.loadConnectMapPointChanged);

      onChange && onChange(newData);
    },
    [onChange, places]
  );

  const handleDelete = useCallback(
    (index: number) => {
      const newData = [...places];

      if (itemList.length <= 2) {
        return;
        // onChange && onChange(newData.map((item, idx) => {
        // 	if (idx === index) {
        // 		const data: LoadInfo_Place = { address: "" }
        // 		return data;
        // 	}
        // 	return item;
        // }))
      } else {
        newData.splice(index, 1);
        sendAmplitudeData(AMPLITUDE_EVENTS.loadConnectMapPointDeleted);
        onChange && onChange(newData);
      }
    },
    [itemList.length, onChange, places]
  );

  const handleDestinationAdd = useCallback(() => {
    if (itemList.length >= 3) return;
    onChange([...itemList, { address: "" }]);
    sendAmplitudeData(AMPLITUDE_EVENTS.loadConnectMapPointAdded);
  }, [itemList, onChange]);

  return (
    <div className="flex flex-col mb-4">
      <DragContent onDrop={handleDrop}>
        <RadioGroup value={radio} className="picker-list">
          {itemList.map((item, index) => (
            <Draggable key={index} draggableId={String(index)} index={index}>
              {(provided) => (
                <div
                  className="flex gap-2 items-center"
                  ref={provided.innerRef}
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                >
                  <EllipsisIcon />

                  <AddressAutocomplete
                    className="flex-grow"
                    value={item}
                    onFocus={() => {
                      setRadio(item.address);
                      onFocus && onFocus(item);
                    }}
                    label={`Point #${index + 1}`}
                    onDelete={() => handleDelete(index)}
                    onChange={(data: LoadInfo_Place) => {
                      handleChange(data, index);
                    }}
                  />
                </div>
              )}
            </Draggable>
          ))}
        </RadioGroup>
      </DragContent>
      {itemList.length < 3 && (
        <Button
          variant="light"
          color="primary"
          startContent={<PlusIcon />}
          className="mt-3 data-[pressed=true]:scale-100 cursor-pointer"
          disabled={!!!itemList[itemList.length - 1]?.coordinates}
          onPress={handleDestinationAdd}
        >
          Add destination
        </Button>
      )}
    </div>
  );
};

export default PointPicker;
