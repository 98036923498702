import { CategoryScale, ChartData } from "chart.js";
import Chart from "chart.js/auto";
import { useMemo } from "react";
import { useAppSelector } from "@/redux/store";
import {
  toClockTimeBySeconds,
  toTimeObjectBySeconds,
} from "@/utils/toClockTime";
import {
  BarChartData,
  TAnalyticsLoadboardTimeGraph,
  TAnalyticsLoadboardTimeGraphData,
  TooltipCountData,
  TUserOrganisationMember,
} from "@/types";
import { BarChart } from "@/components/bar-chart";
import { LoadingCircle } from "@/components/loading-circle";

Chart.register(CategoryScale);

const colors = [
  "#00008B",
  "#0000CD",
  "#0000FF",
  "#1E90FF",
  "#00BFFF",
  "#87CEEB",
  "#ADD8E6",
  "#B0E0E6",
  "#AFEEEE",
  "#5F9EA0",
  "#4682B4",
  "#6495ED",
  "#00CED1",
  "#20B2AA",
  "#48D1CC",
  "#40E0D0",
  "#00FFFF",
  "#E0FFFF",
  "#F0FFFF",
  "#F0F8FF",
];

export const LoadboardTimeGraph = () => {
  const loadboardTimeGraphData: TAnalyticsLoadboardTimeGraph[] = useAppSelector(
    (state) => state.analyticsReducer.loadboardTimeGraphData
  );
  const membersList: TUserOrganisationMember[] = useAppSelector(
    (state) => state.myTeamReducer.members
  );
  const loading = useAppSelector(
    (state) => state.analyticsReducer.loadboardTimeGraphLoading
  );

  const totalString = useMemo(() => {
    const totalTime = loadboardTimeGraphData.reduce(
      (acc, item) => (acc += +item.totalTime),
      0
    );

    return toClockTimeBySeconds(totalTime);
  }, [loadboardTimeGraphData]);

  const data: BarChartData = useMemo(() => {
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const times: TooltipCountData[] = [];
    const calls: TooltipCountData[] = [];
    const emails: TooltipCountData[] = [];
    const labels: TooltipCountData[] = [];

    const datasets: ChartData<"bar", number[], string>["datasets"] =
      loadboardTimeGraphData.reduce<
        ChartData<"bar", number[], string>["datasets"]
      >((acc, item, id) => {
        const timeSpent: number[] = [];
        const loadboardTimeGraph: TAnalyticsLoadboardTimeGraphData[] =
          item.data;
        const email = membersList.find(
          (member) => member.userId?.toString() === item.userId?.toString()
        )?.email;

        const itemTimes: TooltipCountData = [];
        const itemCalls: TooltipCountData = [];
        const itemEmails: TooltipCountData = [];
        const itemLabels: TooltipCountData = [];

        Object.values(loadboardTimeGraph).forEach((dayData) => {
          const timeParts = toTimeObjectBySeconds(
            +dayData["user_session.sum_active_seconds"]
          );
          const decimalHours =
            timeParts.hours + timeParts.minutes / 60 + timeParts.seconds / 3600;
          if (decimalHours) timeSpent.push(decimalHours);

          itemTimes.push({
            value: toClockTimeBySeconds(
              +dayData["user_session.sum_active_seconds"]
            ),
            percentage: dayData.percentageTime,
            up: dayData.percentageTime > 0,
          });
          itemCalls.push({
            value: dayData.phone,
            percentage: dayData.percentagePhone,
            up: dayData.percentagePhone > 0,
          });
          itemEmails.push({
            value: dayData.email,
            percentage: dayData.percentageEmail,
            up: dayData.percentageEmail > 0,
          });
          itemLabels.push({
            value: email || "",
          });
        });

        times.push(itemTimes);
        calls.push(itemCalls);
        emails.push(itemEmails);
        labels.push(itemLabels);

        return timeSpent.length === 0
          ? acc
          : [
              ...acc,
              {
                label: "Time",
                data: timeSpent,
                borderRadius: 5,
                maxBarThickness: 20,
                backgroundColor: colors[id],
              },
            ];
      }, []);

    const data: ChartData<"bar", number[], string> = {
      labels: daysOfWeek,
      datasets,
    };

    return {
      data,
      tooltipData: {
        calls,
        emails,
        times,
        labels,
      },
    };
  }, [loadboardTimeGraphData, membersList]);

  return (
    <div className="w-full h-[300px] px-4 py-3 rounded-lg border border-gray-200 flex gap-2 flex-col relative">
      <LoadingCircle show={loading} />
      <div className="flex w-full justify-between s">
        <h4 className="text-gray-500 text-sm font-normal flex items-center gap-2">
          Time on load board
        </h4>
        <h5 className="text-gray-950 text-lg font-medium">{totalString}</h5>
      </div>

      <BarChart chartData={data} />
    </div>
  );
};
