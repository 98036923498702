import {
  getUserPhoneAnalyticsData,
  getUserEmailAnalyticsData,
  getUserAVGPostToContactTimeAnalyticsData,
  getUserAVGProductiveTimeAnalyticsData,
  getUserAVGRateAnalyticsData,
  getAllQueries,
  getUserLoadboardTimeGraph,
  getAllQueriesFilters,
  getDisPatchLeaderBoard,
} from "@/server/analytics.service";
import { getCubePerformanceGraphWithInquiries } from "@/server/cube.service";
import { TAnalyticsPeriod, TAnalyticsQueryCriteria } from "@/types";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getUserAnalyticsData = createAsyncThunk(
  "analytics/getUserPhoneAnalyticsData",
  async (param: { period: TAnalyticsPeriod; userId: string }) => {
    const { period, userId } = param;
    const promices = [
      getUserPhoneAnalyticsData(period, [userId.toString()]),
      getUserEmailAnalyticsData(period, [userId.toString()]),
      getUserAVGPostToContactTimeAnalyticsData(period, [userId.toString()]),
      getUserAVGProductiveTimeAnalyticsData(period, [userId.toString()]),
      getUserAVGRateAnalyticsData(period, [userId.toString()]),
    ];

    const data = await Promise.allSettled(promices);

    return {
      phoneData: data[0].status === "fulfilled" ? data[0].value : null,
      emailData: data[1].status === "fulfilled" ? data[1].value : null,
      avgPostToContactTimeData:
        data[2].status === "fulfilled" ? data[2].value : null,
      avgProductiveTimeData:
        data[3].status === "fulfilled" ? data[3].value : null,
      avgRateData: data[4].status === "fulfilled" ? data[4].value : null,
    };
  }
);

export const getManagerAnalyticsData = createAsyncThunk(
  "analytics/getManagerPhoneAnalyticsData",
  async (param: { period: TAnalyticsPeriod; selectedUsers: string[] }) => {
    const { period, selectedUsers } = param;
    const promices = [
      getUserPhoneAnalyticsData(period, selectedUsers),
      getUserEmailAnalyticsData(period, selectedUsers),
      getUserAVGPostToContactTimeAnalyticsData(period, selectedUsers),
      getUserAVGProductiveTimeAnalyticsData(period, selectedUsers),
      getUserAVGRateAnalyticsData(period, selectedUsers),
    ];

    const data = await Promise.allSettled(promices);

    return {
      phoneData: data[0].status === "fulfilled" ? data[0].value : null,
      emailData: data[1].status === "fulfilled" ? data[1].value : null,
      avgPostToContactTimeData:
        data[2].status === "fulfilled" ? data[2].value : null,
      avgProductiveTimeData:
        data[3].status === "fulfilled" ? data[3].value : null,
      avgRateData: data[4].status === "fulfilled" ? data[4].value : null,
    };
  }
);

export const getUserQueriesFilters = createAsyncThunk(
  "analytics/getUserQueriesFilters",
  async () => {
    return await getAllQueriesFilters();
  }
);

export const getUserQueries = createAsyncThunk(
  "analytics/getUserQueries",
  async (criteria: TAnalyticsQueryCriteria) => {
    return await getAllQueries(criteria);
  }
);

export const getUserLoadboardTimeGraphData = createAsyncThunk(
  "analytics/getLoadboardTimeGraphData",
  async (userId: string) => {
    return await getUserLoadboardTimeGraph([userId]);
  }
);

export const getManagerLoadboardTimeGraphData = createAsyncThunk(
  "analytics/getManagerLoadboardTimeGraphData",
  async (selectedUser: string[]) => {
    return await getUserLoadboardTimeGraph(selectedUser);
  }
);

export const getUserPerformanceGraphData = createAsyncThunk(
  "analytics/getPerformanceGraphData",
  async (param: { period: TAnalyticsPeriod; userIds: string[] }) => {
    return await getCubePerformanceGraphWithInquiries(
      param.period,
      param.userIds
    );
  }
);

export const getDispatchLeaderBoard = createAsyncThunk(
  "analytics/getDispatchLeaderBoard",
  async (param: { period: TAnalyticsPeriod; userIds: string[] }) => {
    return await getDisPatchLeaderBoard(param.period, param.userIds);
  }
);
