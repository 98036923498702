import {
  TAnalyticsData,
  TAnalyticsLoadboardTimeGraphData,
  TAnalyticsPeriod,
} from "@/types";
import { toISODate } from "@/utils/toISODate";
import { Query } from "@cubejs-client/core";
import { TZDate } from "@date-fns/tz";
import {
  startOfWeek,
  startOfMonth,
  startOfYear,
  endOfYear,
  endOfDay,
  subWeeks,
  endOfWeek,
  endOfMonth,
  subMonths,
  subYears,
  startOfDay,
  subDays,
} from "date-fns";

export const queryUserPhoneAnalytics = (
  granularity: string,
  dateRange: any,
  measure: string,
  userIds: string[]
): Query => {
  const query = initQuery();
  query.measures = [measure];
  query.filters = [
    {
      member: "load_event_table.type_of_contact",
      operator: "equals",
      values: ["PHONE"],
    },
    {
      values: userIds,
      member: "load_event_table.user_id",
      operator: "equals",
    },
  ];
  query.timeDimensions = [
    {
      dimension: "load_event_table.contact_time",
      granularity: granularity,
      dateRange: dateRange,
    },
  ];
  query.order = {
    "load_event_table.contact_time": "desc",
  };
  return query;
};

export const queryUserEmailAnalytics = (
  granularity: string,
  dateRange: any,
  measure: string,
  userIds: string[]
): Query => {
  const query = initQuery();
  query.measures = [measure];
  query.filters = [
    {
      member: "load_event_table.type_of_contact",
      operator: "equals",
      values: ["EMAIL"],
    },
    {
      values: userIds,
      member: "load_event_table.user_id",
      operator: "equals",
    },
  ];
  query.timeDimensions = [
    {
      dimension: "load_event_table.contact_time",
      granularity: granularity,
      dateRange: dateRange,
    },
  ];
  query.order = {
    "load_event_table.contact_time": "desc",
  };
  return query;
};

export const queryAVGRateAnalytics = (
  granularity: string,
  dateRange: any,
  measure: string,
  userIds: string[]
): Query => {
  const query = initQuery();
  query.measures = [measure];
  query.timeDimensions = [
    {
      dimension: "load_event_table.contact_time",
      granularity: granularity,
      dateRange: dateRange,
    },
  ];
  query.filters = [
    {
      values: userIds,
      member: "load_event_table.user_id",
      operator: "equals",
    },
  ];
  query.order = {
    "load_event_table.contact_time": "desc",
  };
  return query;
};

export const queryAVGPostToContactTimeAnalytics = (
  granularity: string,
  dateRange: any,
  measure: string,
  userIds: string[]
): Query => {
  const query = initQuery();
  query.measures = [measure];
  query.timeDimensions = [
    {
      dimension: "load_event_table.contact_time",
      granularity: granularity,
      dateRange: dateRange,
    },
  ];
  query.filters = [
    {
      values: userIds,
      member: "load_event_table.user_id",
      operator: "equals",
    },
  ];
  query.order = {
    "load_event_table.contact_time": "desc",
  };
  return query;
};

export const queryProductiveTimeAnalytics = (
  granularity: string,
  dateRange: any,
  measure: string,
  userIds: string[]
): Query => {
  const query = initQuery();
  query.measures = [measure];
  query.timeDimensions = [
    {
      dimension: "user_session.start_time",
      granularity: granularity,
      dateRange: dateRange,
    },
  ];
  query.order = {
    "user_session.start_time": "desc",
  };
  query.filters = [
    {
      values: userIds,
      member: "user_session.user_id",
      operator: "equals",
    },
  ];
  return query;
};

export const queryLoadBoardTimeGraphAnalytics = (
  dateRange: string,
  measure: string,
  userIds: string[]
): Query => {
  const query = initQuery();
  query.measures = [measure];
  query.timeDimensions = [
    {
      dimension: "user_session.start_time",
      granularity: "day",
      dateRange: dateRange,
    },
  ];
  query.order = {
    "user_session.start_time": "asc",
  };
  query.filters = [
    {
      values: userIds,
      member: "user_session.user_id",
      operator: "equals",
    },
  ];
  query.dimensions = ["user_session.user_id"];
  delete query.limit;
  return query;
};

export const queryInquiriesAnalytics = (
  dateRange: string,
  measure: string,
  userIds: string[]
): Query => {
  const query = initQuery();
  query.measures = [measure];
  query.timeDimensions = [
    {
      dimension: "load_event_table.contact_time",
      granularity: "day",
      dateRange: dateRange,
    },
  ];
  query.order = {
    "load_event_table.contact_time": "asc",
  };
  query.filters = [
    {
      member: "load_event_table.truck_type",
      operator: "set",
    },
    {
      values: userIds,
      member: "load_event_table.user_id",
      operator: "equals",
    },
  ];
  query.dimensions = [
    "load_event_table.user_id",
    "load_event_table.type_of_contact",
  ];
  delete query.limit;
  return query;
};

export const queryPerformanceByLoadboards = (
  dateRange: string,
  measure: string,
  userIds: string[]
): Query => {
  const query = initQuery();
  query.measures = [measure];

  query.timeDimensions = [
    {
      dimension: "user_session.start_time",
      dateRange: dateRange,
    },
  ];
  query.dimensions = ["user_session.source"];
  query.filters = [
    {
      values: userIds,
      member: "user_session.user_id",
      operator: "equals",
    },
  ];
  delete query.limit;
  return query;
};

export const queryInquiriesByLoadboards = (
  granularity: string,
  dateRange: any,
  measure: string,
  userIds: string[]
): Query => {
  const query = initQuery();
  query.measures = [measure];
  query.timeDimensions = [
    {
      dimension: "load_event_table.contact_time",
      dateRange: dateRange,
      granularity: granularity,
    },
  ];
  query.filters = [
    {
      values: userIds,
      member: "load_event_table.user_id",
      operator: "equals",
    },
  ];
  query.dimensions = ["load_event_table.source"];
  delete query.limit;
  return query;
};

export const queryDispatchLoadbord = (
  granularity: string,
  dateRange: any,
  userIds: string[]
): Query => {
  const query = initQuery();
  query.timeDimensions = [
    {
      dimension: "load_event_table.contact_time",
      granularity: granularity,
      dateRange: dateRange,
    },
  ];
  query.filters = [
    {
      values: userIds,
      member: "load_event_table.user_id",
      operator: "equals",
    },
  ];
  query.measures = [
    "load_event_table.count",
    "load_event_table.avg_rate",
    "load_event_table.avg_post_to_contact_time",
    "user_session.sum_active_seconds",
  ];
  query.dimensions = [
    "load_event_table.user_id",
    "load_event_table.type_of_contact",
  ];
  delete query.limit;
  return query;
};

export const transformInquiriesByLoadboard = (
  data: any[],
  measure: string
): any => {
  const records = JSON.parse(
    JSON.stringify(data).replaceAll("," + measure, "")
  );
  let record: any = {};
  for (const [key, value] of Object.entries(records[1])) {
    if (!key.includes("time")) {
      const previousCount = Number(records[0][key]);
      const currentCount = Number(value);
      const total = Number.isInteger(currentCount)
        ? currentCount.toString()
        : currentCount.toFixed(0).toString();
      const percentage =
        previousCount === 0
          ? "100"
          : (((currentCount - previousCount) / previousCount) * 100).toFixed(0);
      const up = currentCount > previousCount;
      const comparison_with_the_past = Number.isInteger(previousCount)
        ? previousCount.toString()
        : previousCount.toFixed(0).toString();
      record[key] = {
        total,
        percentage,
        up,
        comparison_with_the_past,
      };
    } else {
      record[key] = value;
    }
  }
  return record;
};

export const transformAnalytics = (
  data: any,
  measure: string
): TAnalyticsData => {
  const previousCount = data[0][measure] ? Number(data[0][measure]) : 0;
  const currentCount = data[1][measure] ? Number(data[1][measure]) : 0;
  const total = Number.isInteger(currentCount)
    ? currentCount.toString()
    : currentCount.toFixed(0).toString();
  const percentage =
    previousCount === 0
      ? "100"
      : (((currentCount - previousCount) / previousCount) * 100).toFixed(0);
  const up = currentCount > previousCount;
  const comparison_with_the_past = Number.isInteger(previousCount)
    ? previousCount.toString()
    : previousCount.toFixed(0).toString();

  return {
    total,
    percentage,
    up,
    rpm: "",
    comparison_with_the_past,
  };
};

export const transformTimeGraph = (
  inquiriesData: any[],
  timeOnloadBoards: any[],
  userId: string
) => {
  const aggregatedData: TAnalyticsLoadboardTimeGraphData[] = [];
  let totalTime = 0;
  for (var i = 0; i < timeOnloadBoards.length; i++) {
    let common: any = {};
    const timeOnloadBoard = timeOnloadBoards[i];
    common = { ...timeOnloadBoard };

    const inquiry = inquiriesData.find(
      (val) =>
        val["load_event_table.contact_time.day"] ===
        timeOnloadBoard["user_session.start_time.day"]
    );
    if (inquiry) {
      common = {
        ...common,
        email: Number(inquiry.EMAIL) || 0,
        phone: Number(inquiry.PHONE) || 0,
      };
    } else {
      common = {
        ...common,
        email: 0,
        phone: 0,
      };
    }
    totalTime += +common["user_session.sum_active_seconds"];

    if (i > 0) {
      const prevCommon: TAnalyticsLoadboardTimeGraphData =
        aggregatedData[i - 1];
      const percentageEmail =
        prevCommon.email === 0
          ? common.email === 0
            ? 0
            : 100
          : (
              ((common.email - prevCommon.email) / prevCommon.email) *
              100
            ).toFixed(0);
      const percentagePhone =
        prevCommon.phone === 0
          ? common.phone === 0
            ? 0
            : 100
          : (
              ((common.phone - prevCommon.phone) / prevCommon.phone) *
              100
            ).toFixed(0);
      const percentageTime =
        prevCommon["user_session.sum_active_seconds"] === 0
          ? common["user_session.sum_active_seconds"] === 0
            ? 0
            : 100
          : (
              ((common["user_session.sum_active_seconds"] -
                prevCommon["user_session.sum_active_seconds"]) /
                prevCommon["user_session.sum_active_seconds"]) *
              100
            ).toFixed(0);
      common = {
        ...common,
        percentageEmail,
        percentageEmailUp: prevCommon.email < common.email,
        percentagePhone,
        percentagePhoneUp: prevCommon.phone < common.phone,
        percentageTime,
        percentageTimeUp:
          prevCommon["user_session.sum_active_seconds"] <
          common["user_session.sum_active_seconds"],
      };
    } else {
      common = {
        ...common,
        percentageEmail: 0,
        percentagePhone: 0,
        percentageTime: 0,
      };
    }

    aggregatedData.push(common);
  }

  return {
    data: aggregatedData,
    totalTime: totalTime,
    userId: userId,
  };
};

export const transformDispatchLeaderBoard = (
  period: TAnalyticsPeriod,
  data: any[],
  userIds: string[]
) => {
  const transformedData: any[] = [];
  const timeKey = `load_event_table.contact_time.` + period;
  userIds.forEach((userId) => {
    const userData = data.map((item) => {
      const filteredItem: any = { timeKey: item[timeKey] };
      Object.entries(item).forEach(([key, value]) => {
        if (key.startsWith(`${userId},`)) {
          filteredItem[key] = value;
        }
      });
      return filteredItem;
    });
    transformedData.push({ userId, data: userData });
  });
  for (let user of transformedData) {
    const prevPhoneCount = user.data[0][
      user.userId + ",PHONE,load_event_table.count"
    ]
      ? user.data[0][user.userId + ",PHONE,load_event_table.count"]
      : 0;
    const prevEmailCount = user.data[0][
      user.userId + ",EMAIL,load_event_table.count"
    ]
      ? user.data[0][user.userId + ",EMAIL,load_event_table.count"]
      : 0;
    const prevAvgPostToContactTimePhone = user.data[0][
      user.userId + ",PHONE,load_event_table.avg_post_to_contact_time"
    ]
      ? user.data[0][
          user.userId + ",PHONE,load_event_table.avg_post_to_contact_time"
        ]
      : 0;
    const prevAvgPostToContactTimeEmail = user.data[0][
      user.userId + ",EMAIL,load_event_table.avg_post_to_contact_time"
    ]
      ? user.data[0][
          user.userId + ",EMAIL,load_event_table.avg_post_to_contact_time"
        ]
      : 0;
    const prevAvgRatePhone = user.data[0][
      user.userId + ",PHONE,load_event_table.avg_rate"
    ]
      ? user.data[0][user.userId + ",PHONE,load_event_table.avg_rate"]
      : 0;
    const prevAvgRateEmail = user.data[0][
      user.userId + ",EMAIL,load_event_table.avg_rate"
    ]
      ? user.data[0][user.userId + ",EMAIL,load_event_table.avg_rate"]
      : 0;
    const prevAvgProductiveTime = user.data[0][
      user.userId + ",EMAIL,user_session.sum_active_seconds"
    ]
      ? user.data[0][user.userId + ",EMAIL,user_session.sum_active_seconds"]
      : 0;

    user.phoneCount = user.data[1][
      user.userId + ",PHONE,load_event_table.count"
    ]
      ? user.data[1][user.userId + ",PHONE,load_event_table.count"]
      : 0;
    user.emailCount = user.data[1][
      user.userId + ",EMAIL,load_event_table.count"
    ]
      ? user.data[1][user.userId + ",EMAIL,load_event_table.count"]
      : 0;
    user.avgPostToContactTimePhone = user.data[1][
      user.userId + ",PHONE,load_event_table.avg_post_to_contact_time"
    ]
      ? user.data[1][
          user.userId + ",PHONE,load_event_table.avg_post_to_contact_time"
        ]
      : 0;
    user.avgPostToContactTimeEmail = user.data[1][
      user.userId + ",EMAIL,load_event_table.avg_post_to_contact_time"
    ]
      ? user.data[1][
          user.userId + ",EMAIL,load_event_table.avg_post_to_contact_time"
        ]
      : 0;

    user.avgRatePhone = user.data[1][
      user.userId + ",PHONE,load_event_table.avg_rate"
    ]
      ? user.data[1][user.userId + ",PHONE,load_event_table.avg_rate"]
      : 0;

    user.avgRateEmail = user.data[1][
      user.userId + ",EMAIL,load_event_table.avg_rate"
    ]
      ? user.data[1][user.userId + ",EMAIL,load_event_table.avg_rate"]
      : 0;

    user.avgProductiveTime = user.data[1][
      user.userId + ",EMAIL,user_session.sum_active_seconds"
    ]
      ? user.data[1][user.userId + ",EMAIL,user_session.sum_active_seconds"]
      : 0;

    user.phonePercentage =
      +user.phoneCount === 0
        ? +user.phoneCount < +prevPhoneCount
          ? -100
          : 0
        : ((+prevPhoneCount * 100) / +user.phoneCount).toFixed(0);
    user.emailPercentage =
      +user.emailCount === 0
        ? +user.emailCount < +prevEmailCount
          ? -100
          : 0
        : ((+prevEmailCount * 100) / +user.emailCount).toFixed(0);

    user.avgPostToContactTimePercentagePhone =
      +user.avgPostToContactTimePhone === 0
        ? +user.avgPostToContactTimePhone < +prevAvgPostToContactTimePhone
          ? -100
          : 0
        : (
            (+prevAvgPostToContactTimePhone * 100) /
            +user.avgPostToContactTimePhone
          ).toFixed(0);

    user.avgPostToContactTimePercentageEmail =
      +user.avgPostToContactTimeEmail === 0
        ? +user.avgPostToContactTimeEmail < +prevAvgPostToContactTimeEmail
          ? -100
          : 0
        : (
            (+prevAvgPostToContactTimeEmail * 100) /
            +user.avgPostToContactTimeEmail
          ).toFixed(0);

    user.avgRateEmailPercentage =
      +user.avgRateEmail === 0
        ? +user.avgRateEmail < +prevAvgRateEmail
          ? -100
          : 0
        : ((+prevAvgRateEmail * 100) / +user.avgRateEmail).toFixed(0);
    user.avgRatePhonePercentage =
      +user.avgRatePhone === 0
        ? +user.avgRatePhone < +prevAvgRatePhone
          ? -100
          : 0
        : ((+prevAvgRatePhone * 100) / +user.avgRatePhone).toFixed(0);
    user.avgProductiveTimePercentage =
      +user.avgProductiveTime === 0
        ? +user.avgProductiveTime < +prevAvgProductiveTime
          ? -100
          : 0
        : ((+prevAvgProductiveTime * 100) / +user.avgProductiveTime).toFixed(0);

    user.avgRateEmailUp = user.avgRateEmail > prevAvgRateEmail;
    user.avgRatePhoneUp = user.avgRatePhone > prevAvgRatePhone;
    user.phoneUp = user.phoneCount > prevPhoneCount;
    user.emailUp = user.emailCount > prevEmailCount;
    user.avgPostToContactTimeEmailUp =
      user.avgPostToContactTimeEmail > prevAvgPostToContactTimeEmail;
    user.avgPostToContactTimePhoneUp =
      user.avgPostToContactTimePhone > prevAvgPostToContactTimePhone;
    user.avgProductiveTimeUp = user.avgProductiveTime > prevAvgProductiveTime;

    user.prevEmailCount = prevEmailCount;
    user.prevPhoneCount = prevPhoneCount;
    user.prevAvgRateEmail = prevAvgRateEmail;
    user.prevAvgRatePhone = prevAvgRatePhone;
    user.prevAvgPostToContactTimeEmail = prevAvgPostToContactTimeEmail;
    user.prevAvgPostToContactTimePhone = prevAvgPostToContactTimePhone;
    user.prevAvgProductiveTime = prevAvgProductiveTime;
  }
  return transformedData;
};

//todo fix dates
export const accumulatePeriodToDateRange = (period: TAnalyticsPeriod) => {
  if (period === "day") {
    return "today";
  } else if (period === "week") {
    return "this week";
  } else if (period === "month") {
    return "this month";
  }
  return "this year";
};

export const acummulateDates = (period: TAnalyticsPeriod) => {
  const granularity = period;
  const now = new TZDate().withTimeZone("America/New_York");
  let startDate, endDate;

  switch (period) {
    case "day":
      startDate = toISODate(startOfDay(subDays(now, 1)));
      endDate = toISODate(endOfDay(now));
      break;
    case "week":
      startDate = toISODate(startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }));
      endDate = toISODate(endOfWeek(now));
      break;
    case "month":
      startDate = toISODate(startOfMonth(subMonths(now, 1)));
      endDate = toISODate(endOfMonth(now));
      break;
    case "year":
      startDate = toISODate(startOfYear(subYears(now, 1)));
      endDate = toISODate(endOfYear(now));
      break;
    default:
      throw new Error("Invalid period specified");
  }

  return { granularity, dateRange: [startDate, endDate] };
};

export const initQuery = (): Query => {
  return {
    dimensions: [],
    measures: [],
    timeDimensions: [],
    filters: [],
    limit: 2,
    order: {},
    timezone: "America/New_York",
  };
};
