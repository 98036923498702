import { AddTeamMember, MyTeamData } from "@/components/my-team/";
import { useAppSelector } from "@/redux/store";
import { LockedView } from "@/components/my-team/components/LockedView";
import { TUserOrganisation, TUserOrganisationMember } from "@/types";

export const MyTeam = () => {
  const { features, organizationId } = useAppSelector(
    (state) => state.authReducer.value
  );
  const organization: TUserOrganisation = useAppSelector(
    (state) => state.myTeamReducer.data
  );
  const membersList: TUserOrganisationMember[] = useAppSelector(
    (state) => state.myTeamReducer.members
  );
  const isMyTeamAvailable = features.includes("team-management");

  if (!isMyTeamAvailable && !organizationId) {
    return <LockedView />;
  }

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex gap-4 items-center justify-between">
        <div className="flex items-baseline gap-1">
          <h1 className="font-medium text-xl">
            {organization.name || "My Team"}
          </h1>
          <p className="text-lg">
            ({membersList.length} of {organization.limitUsers} users)
          </p>
        </div>

        <AddTeamMember />
      </div>
      <MyTeamData />
    </div>
  );
};
