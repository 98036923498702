import { Input, SharedSelection } from "@nextui-org/react";
import { ReactComponent as SearchIcon } from "@/assets/icons/search-icon.svg";
import { ReactComponent as CircularLinesIcon } from "@/assets/icons/circulat-lines-icon.svg";
import { FitlersSelect } from "@/components/filters-select";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  setMyTeamFilterRole,
  setMyTeamFilterSearch,
  setMyTeamFilterStatus,
} from "@/redux/slices/my-team-slice";
import { UserInviteStatus, UserRole } from "@/types";

const statuses: {
  id: Lowercase<UserInviteStatus> | "all";
  name: string;
}[] = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "invited",
    name: "Pending",
  },
  {
    id: "accepted",
    name: "Accepted",
  },
  {
    id: "expired",
    name: "Expired",
  },
];

const roles: {
  id: Lowercase<UserRole> | "all";
  name: string;
}[] = [
  {
    id: "all",
    name: "All",
  },
  {
    id: "manager",
    name: "Manager",
  },
  {
    id: "user",
    name: "User",
  },
];

export const MyTeamFilters = () => {
  const dispatch = useAppDispatch();

  const search = useAppSelector((state) => state.myTeamReducer.filter.search);
  const status = useAppSelector((state) => state.myTeamReducer.filter.status);
  const role = useAppSelector((state) => state.myTeamReducer.filter.role);

  const handleSearchChange = (value: string) => {
    dispatch(setMyTeamFilterSearch(value));
  };

  const handleStatusChange = (selectedKeys: SharedSelection) => {
    dispatch(
      setMyTeamFilterStatus(selectedKeys.currentKey as UserInviteStatus)
    );
  };

  const handleRoleChange = (selectedKeys: SharedSelection) => {
    dispatch(setMyTeamFilterRole(selectedKeys.currentKey as UserRole));
  };

  return (
    <div className="w-full h-10 flex gap-4">
      <Input
        isClearable
        placeholder="Search"
        variant="bordered"
        className="w-full max-w-80"
        startContent={<SearchIcon />}
        value={search}
        onValueChange={handleSearchChange}
      />
      <FitlersSelect
        label="Status"
        icon={<CircularLinesIcon />}
        options={statuses}
        selectedKeys={[status]}
        onSelectionChange={handleStatusChange}
      />
      <FitlersSelect
        label="Role"
        options={roles}
        selectedKeys={[role]}
        onSelectionChange={handleRoleChange}
      />
    </div>
  );
};
