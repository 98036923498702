import { Card, CardBody, Button, Input, Link, cn } from "@nextui-org/react";
import React, { FC, useMemo } from "react";
import { CheckIcon, DoubleCheckIcon } from "@/components/icons";
import { useAppSelector } from "@/redux/store";
import { TPeriod, TPlan } from "@/types";
import { CONTACT_US_LINK } from "@/constants/links";

export const PlanCard: FC<{ plan: TPlan; period: TPeriod }> = ({
  plan,
  period,
}) => {
  const { accessToken } = useAppSelector((state) => state.authReducer.value);
  const [dispatchersCount, setDispatchersCount] = React.useState(
    plan.isMultipleDispatchers ? 5 : plan.dispatchersCount
  );

  const isYearly = useMemo(() => period === "yearly", [period]);
  const monthlyPrice = useMemo(
    () =>
      plan.getPriceByDispatchers
        ? plan.getPriceByDispatchers(dispatchersCount)
        : plan.price,
    [dispatchersCount, plan]
  );
  const yearlyPrice = useMemo(
    () =>
      +(
        (plan.getPriceByDispatchers
          ? plan.getPriceByDispatchers(dispatchersCount)
          : plan.price) * 0.9
      ).toFixed(0),
    [dispatchersCount, plan]
  );

  const paymentLink = useMemo(() => {
    let link = `${
      isYearly ? plan.paymentLinkYearly : plan.paymentLink
    }&accessToken=${accessToken}${
      plan.isMultipleDispatchers
        ? `&unit=${
            (isYearly ? yearlyPrice : monthlyPrice) * 100 * dispatchersCount
          }`
        : ""
    }`;

    return link;
  }, [
    accessToken,
    dispatchersCount,
    isYearly,
    monthlyPrice,
    plan.isMultipleDispatchers,
    plan.paymentLink,
    plan.paymentLinkYearly,
    yearlyPrice,
  ]);

  const handleDispatchersCountChange = (value: string) => {
    setDispatchersCount(+value);
  };

  const handleDecrement = () => {
    if (dispatchersCount > plan.dispatchersCount) {
      setDispatchersCount(dispatchersCount - 1);
    }
  };

  const handleIncrement = () => {
    setDispatchersCount(+dispatchersCount + 1);
  };

  const className = cn({
    "bg-gray-50 p-4 max-w-96 min-w-80": true,
    "border-2 border-brand-700": plan.promo,
  });

  return (
    <Card shadow="none" className={className}>
      <CardBody className="flex flex-col gap-7">
        <h4>{plan.name}</h4>
        <p className="-mt-5">{plan.description}</p>
        {dispatchersCount > 15 ? (
          <h2 className="text-5xl h-11">Let's Talk!</h2>
        ) : (
          <div className="flex items-start text-gray-950">
            <p className="text-lg">$</p>
            <h3 className="text-5xl h-11">
              {isYearly ? yearlyPrice : monthlyPrice}
            </h3>
            <div className="flex flex-col justify-end h-full ml-2 text-sm text-gray-600">
              <p>{plan.isMultipleDispatchers ? "/dispatcher" : "/month"}</p>

              {isYearly ? (
                <p>
                  billed at <s>${monthlyPrice * 12 * dispatchersCount}</s>{" "}
                  <b>${yearlyPrice * 12 * dispatchersCount}/year</b>
                </p>
              ) : (
                plan.isMultipleDispatchers && (
                  <p>
                    billed at <b>${monthlyPrice * dispatchersCount}/month</b>
                  </p>
                )
              )}
            </div>
          </div>
        )}
        {plan.isMultipleDispatchers ? (
          <div className="flex gap-2">
            <Input
              variant="flat"
              value={dispatchersCount.toString()}
              onValueChange={handleDispatchersCountChange}
              classNames={{
                mainWrapper: "hover:bg-white",
                inputWrapper: "bg-white",
                input: "text-center text-black text-sm",
              }}
              min={plan.dispatchersCount}
              startContent={
                <Button
                  isIconOnly
                  className="bg-transparent"
                  onPress={handleDecrement}
                >
                  -
                </Button>
              }
              endContent={
                <Button
                  isIconOnly
                  className="bg-transparent"
                  onPress={handleIncrement}
                >
                  +
                </Button>
              }
            />
            <p className="mb-3 text-gray-600 bg-gray-100 p-3 rounded-md">
              Dispatchers
            </p>
          </div>
        ) : (
          <p className="mb-3 text-gray-600 bg-gray-100 p-3 rounded-md">
            {dispatchersCount} Dispatcher
          </p>
        )}
        <Button
          className="-mt-7 flex-shrink-0"
          color="primary"
          as={Link}
          href={paymentLink}
        >
          Get started
        </Button>
        <ul className="flex flex-col gap-4 h-full">
          {plan.previousPlan && (
            <li className="text-sm font-bold flex items-center gap-1 text-gray-950">
              <span className="text-brand-700">
                <DoubleCheckIcon size={16} />
              </span>{" "}
              Everything in {plan.previousPlan}
            </li>
          )}
          {plan.features.map((feature, index) => (
            <li
              key={index}
              className="text-sm flex items-center gap-1 text-gray-950"
            >
              <span className="text-brand-700">
                <CheckIcon size={16} />
              </span>{" "}
              {feature}
            </li>
          ))}
        </ul>
        {plan.futureFeatures && (
          <div className="-mt-7">
            <p className="my-4 text-brand-700">Coming Soon:</p>
            <ul className="flex flex-col gap-4 h-full">
              {plan.futureFeatures?.map((feature, index) => (
                <li
                  key={index}
                  className="text-sm flex items-center gap-1 text-gray-950"
                >
                  <span className="text-brand-700">
                    <CheckIcon size={16} />
                  </span>{" "}
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        )}
        <Button
          variant={plan.isMultipleDispatchers ? "ghost" : "faded"}
          color="primary"
          href={CONTACT_US_LINK}
          target="_blank"
          as={Link}
          className="flex-shrink-0"
        >
          {plan.isMultipleDispatchers ? "Talk to Sales" : "Learn More"}
        </Button>
      </CardBody>
    </Card>
  );
};
