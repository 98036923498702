export const toClockTime = (time?: string) => {
  if (!time) return "0m";

  const split = time?.split(":");
  const hours = parseInt(split?.[0] || "0").toFixed(0);
  const minutes = parseInt(split?.[1] || "0").toFixed(0);

  return `${hours || ""}${hours ? "h" : ""} ${minutes}m`;
};

export const toClockTimeBySeconds = (seconds: number) => {
  if (!seconds) return "0s";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let timeString = "";

  if (hours > 0) {
    timeString += `${hours.toFixed(0)}h `;
  }
  if (minutes > 0 || hours > 0) {
    timeString += `${minutes.toFixed(0)}m `;
  }
  if (remainingSeconds > 0 || timeString === "") {
    // Also show seconds if no other components are shown
    timeString += `${remainingSeconds.toFixed(0)}s`;
  }

  return timeString.trim(); // Trim to remove any extra spaces
};

export const toTimeObjectBySeconds = (seconds: number) => {
  if (!seconds) return { hours: 0, minutes: 0, seconds: 0 };

  const hours = Math.floor(seconds / 3600) || 0;
  const minutes = Math.floor((seconds % 3600) / 60) || 0;
  const remainingSeconds = seconds % 60 || 0;

  return {
    hours,
    minutes,
    seconds: remainingSeconds,
  };
};
