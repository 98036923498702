import { LoadboardTimeGraph } from "./components/LoadboardTimeGraph";
import { LoadboardPerformance } from "./components/LoadboardPerformance";
import { Stats } from "./components/Stats";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useEffect } from "react";
import {
  getUserLoadboardTimeGraphData,
  getUserAnalyticsData,
} from "@/redux/async/getAnalyticsData";

export const UserAnalytics = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.authReducer.value.userId);
  const period = useAppSelector((state) => state.analyticsReducer.statsPeriod);

  useEffect(() => {
    dispatch(getUserAnalyticsData({ period, userId }));
  }, [dispatch, period, userId]);

  useEffect(() => {
    dispatch(getUserLoadboardTimeGraphData(userId));
  }, [dispatch, userId]);

  const iframeSrc = `https://loadconnect.retool.com/embedded/public/220ca60b-b617-4052-9901-1d859d0a9572?userId=${userId}`;

  return (
    <>
      <div className="col-span-2">
        <Stats />
      </div>
      <LoadboardTimeGraph />
      <LoadboardPerformance />
      <div className="col-span-2">
        <iframe
          title="Inquires Table"
          src={iframeSrc}
          className="w-full h-[910px] border-1 border-gray-200 rounded-lg"
        />
        {/* <InquiriesData /> */}
      </div>
    </>
  );
};
