import {
  BookmarkIcon,
  BookmarkFillIcon,
  MarkerIcon,
  ShareIcon,
  TruckIcon,
} from "@/components/icons";
import { Button, Input, cn } from "@nextui-org/react";
import PointPicker from "./point-picker";
import { LoadInfo, LoadNote, McReview } from "@/types";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import transform from "@/utils/transformLabel";
import { debounceSendAmplitudeData, sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";
import { LoadCalculator } from "./calc";
import { toNumber } from "@/utils/toNumber";
import Loading from "@/components/loading";

interface Props {
  loadInfo: LoadInfo;
  routeInfo: LoadInfo["routeInfo"];
  loadNote: LoadNote;
  noteSaving?: boolean;
  onSaveNote?: any;
  onSaveLoad?: any;
  onShareLoad?: any;
  loadSaving: boolean;
  onPlaceChange: any;
  onFocusPlace?: any;
  reviews: McReview[];
  onOpenReivew: any;
  updating: boolean;
}

const LoadContent: FC<Props> = ({
  loadInfo,
  routeInfo,
  loadNote,
  noteSaving,
  loadSaving,
  onSaveNote,
  onSaveLoad,
  onShareLoad,
  onPlaceChange,
  onFocusPlace,
  updating,
}) => {
  const [newNote, setNewNote] = useState("");

  const miles = useMemo(() => {
    const mile = routeInfo?.tripMiles || 0;
    return mile;
  }, [routeInfo.tripMiles]);

  useEffect(() => {
    setNewNote("");
  }, [loadNote]);

  // const avgReview = useMemo(() => {
  //     let result = 0;
  //     reviews.forEach(review => result += review.rating)
  //     return result
  // }, [reviews])

  const handleShareClick = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.loadConnectMapShareButtonClicked);
    onShareLoad();
  };

  const handleSaveClick = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.loadConnectMapSaveLoadButtonClicked);
    onSaveLoad();
  };

  const handleNoteChange = (e: ChangeEvent<HTMLInputElement>) => {
    debounceSendAmplitudeData(AMPLITUDE_EVENTS.loadConnectMapNotesValueChanged);
    setNewNote(e.target.value);
  };

  const handleNoteAddClick = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.loadConnectMapAddNotesButtonClicked);
    onSaveNote && onSaveNote(newNote);
  };

  return (
    <div className="flex flex-col gap-5 h-full overflow-hidden text-black relative">
      {updating && (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-white/80 z-50">
          <Loading />
        </div>
      )}
      <div className="flex justify-between items-center gap-4">
        <div className="flex gap-2">
          <Button
            startContent={<ShareIcon size={20} />}
            className="data-[pressed=true]:scale-100 font-semibold bg-[#EFF0FE]"
            onPress={handleShareClick}
          >
            Share
          </Button>
          <Button
            disabled={loadNote.status === "SAVED"}
            isLoading={loadSaving}
            color={loadNote.status === "SAVED" ? "primary" : "default"}
            onPress={handleSaveClick}
            startContent={
              !loadSaving && loadNote.status === "SAVED" ? (
                <BookmarkFillIcon size={20} />
              ) : (
                <BookmarkIcon size={20} />
              )
            }
            className={cn(
              "data-[pressed=true]:scale-100 font-semibold",
              loadNote.status === "VISITED" && "bg-[#EFF0FE]"
            )}
          >
            {loadNote.status === "SAVED" ? "Saved" : "Save Load"}
          </Button>
        </div>

        <div className="flex flex-col gap-1 items-end">
          {loadInfo.email && (
            <div className="flex gap-2 items-end text-primary cursor-pointer">
              <a
                onClick={() =>
                  sendAmplitudeData(AMPLITUDE_EVENTS.loadConnectMapEmailClicked)
                }
                className=""
                href={`mailto:${loadInfo.email}`}
              >
                {loadInfo.email}
              </a>
            </div>
          )}
          {loadInfo.phone && (
            <div className="flex gap-2 items-end text-primary cursor-pointer">
              <a
                onClick={() =>
                  sendAmplitudeData(
                    AMPLITUDE_EVENTS.loadConnectMapPhoneNumberClicked
                  )
                }
                className=""
                href={`tel:${loadInfo.phone}`}
              >
                {loadInfo.phone}
              </a>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-start items-start">
        <div className="text-base flex flex-col gap-1">
          <span>{loadInfo.company}</span>
          <span>{loadInfo.mc}</span>
        </div>
      </div>

      <div>
        <PointPicker
          places={loadInfo.places}
          onChange={onPlaceChange}
          onFocus={onFocusPlace}
        />
      </div>

      <div className="flex flex-col gap-2 -mt-3">
        <div>
          <p className="text-base m-0 font-bold mb-0.5">Route Info:</p>
          <hr className="mb-2.5" />
        </div>
        <div className="flex justify-between gap-4">
          <Button
            startContent={<TruckIcon size={20} />}
            className="data-[pressed=true]:scale-100 bg-[#eff0fe]"
          >
            {Math.floor(routeInfo.estimatedTimeMinutes / 3600)} hr{" "}
            {Math.floor((routeInfo.estimatedTimeMinutes % 3600) / 60)} min
          </Button>
          <Button
            startContent={<MarkerIcon size={20} />}
            className="data-[pressed=true]:scale-100 bg-[#eff0fe]"
          >
            {miles} ml
          </Button>
          <Button color="primary" className="data-[pressed=true]:scale-100">
            Tolls: ${routeInfo.tolls}
          </Button>
        </div>
      </div>

      <div className="overflow-y-auto">
        <div className="flex flex-col gap-8 flex-grow">
          {/* Load Info */}
          <div className="flex flex-col gap-2.5">
            <div>
              <p className="text-base m-0 font-bold mb-0.5">Load Info:</p>
              <hr className="mb-2.5" />
            </div>
            <div className="grid grid-cols-2">
              {Object.keys(transform(loadInfo.load)).map((key: string) => {
                const value = transform(loadInfo.load)[key];
                return (
                  <div className="flex gap-2" key={key}>
                    <p className="m-0 font-bold">{key}:</p>
                    <p className="m-0">{value}</p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* Additional Info */}
          <div className="flex flex-col">
            <p className="text-base m-0 font-bold mb-0.5">Additional Info:</p>
            <hr className="mb-4" />
            <div className="grid grid-cols-1">
              {Object.keys(transform(loadInfo.additionalData)).map(
                (key: string) => {
                  const value = transform(loadInfo.additionalData)[key];
                  return (
                    <div className="flex gap-2" key={key}>
                      <p className="m-0 font-bold min-w-[100px]">{key}:</p>
                      <p className="m-0">{value}</p>
                    </div>
                  );
                }
              )}
            </div>
          </div>

          {/* Calculator */}
          <div className="flex flex-col">
            <p className="text-base m-0 font-bold mb-0.5">Calculator</p>
            <hr className="mb-4" />
            <LoadCalculator
              defaultValues={{
                miles: miles,
                deadheadMiles: routeInfo?.tripMilesWithDeadhead,
                toll: toNumber(routeInfo.tolls) || 0,
                rate: toNumber(loadInfo.load.rate) || 0,
                fuelPrice: routeInfo.fuelPrice,
              }}
            />
          </div>

          {/* Load Notes */}
          <div className="flex flex-col gap-2.5">
            {!!loadNote.notes && !!loadNote.notes.length && (
              <div className="border rounded-lg p-2 flex flex-col gap-2">
                {loadNote.notes.map((item, key) => (
                  <p className="flex" key={key}>
                    <span
                      key={key}
                      className="relative m-0 p-2 rounded-lg bg-[#EFF0FE] break-all"
                    >
                      {item.text}
                      <span className="flex flex-col justify-end h-5 mx-2 float-right text-gray-500 text-xs">
                        {moment(item.createdAt).format("YYYY/MM/DD HH:mm")}
                      </span>
                    </span>
                  </p>
                ))}
              </div>
            )}
            <div className="flex gap-2">
              <Input
                type="text"
                color="primary"
                variant="bordered"
                onChange={handleNoteChange}
                placeholder="Add your notes here"
              ></Input>
              <Button
                color="primary"
                onPress={handleNoteAddClick}
                disabled={newNote === ""}
                isLoading={noteSaving}
                className="data-[pressed=true]:scale-100 font-medium disabled:opacity-50"
              >
                Add Notes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadContent;
