import {
  QuestionIcon,
  GoogleIcon,
  OutlookIcon,
  MailIcon,
} from "@/components/icons";
import { Button } from "@nextui-org/button";
import { ModalHeader, ModalBody, ModalFooter } from "@nextui-org/modal";
import { Tooltip } from "@nextui-org/tooltip";
import { Link } from "@nextui-org/link";
import { useState } from "react";
import { Divider } from "@nextui-org/react";

const MainService = ({ onRedirect }: { onRedirect: Function }) => {
  const [isLoadingGoogle, setIsLoadingGoogle] = useState<boolean>(false);
  const [isLoadingOutlook, setIsLoadingOutlook] = useState<boolean>(false);

  return (
    <>
      <ModalHeader>
        <h3 className="text-base font-semibold text-gray-900 lg:text-xl dark:text-white">
          Connect Account
        </h3>
      </ModalHeader>
      <ModalBody>
        <div className="flex gap-2 items-center mb-4">
          <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
            Connect with one of your account, to send email on behalf.
          </p>
          <Tooltip
            content={
              <div className="px-1 py-2">
                <div className="text-small font-bold">
                  {" "}
                  Not able to connect your account or find your provider
                </div>
                <div className="text-tiny">
                  Please contact us on{" "}
                  <Link
                    size="sm"
                    isExternal
                    showAnchorIcon
                    href="https://loadconnect.io/ContactPage"
                  >
                    link
                  </Link>
                </div>
              </div>
            }
          >
            <Button
              isIconOnly
              variant="light"
              className="text-gray"
              radius="full"
              size="sm"
            >
              <QuestionIcon size={18} />
            </Button>
          </Tooltip>
        </div>
        <div className="flex flex-col gap-3">
          <Button
            size="md"
            radius="sm"
            variant="bordered"
            color="primary"
            isLoading={isLoadingGoogle}
            startContent={<GoogleIcon />}
            onPress={() => {
              setIsLoadingGoogle(true);
              onRedirect("GOOGLE");
            }}
          >
            Connect Gmail
          </Button>

          <Button
            size="md"
            radius="sm"
            variant="bordered"
            color="primary"
            isLoading={isLoadingOutlook}
            startContent={<OutlookIcon />}
            onPress={() => {
              setIsLoadingOutlook(true);
              onRedirect("OUTLOOK");
            }}
          >
            Connect Outlook
          </Button>

          <div className="flex items-center gap-4">
            <Divider className="shrink" />
            or
            <Divider className="shrink" />
          </div>

          <Button
            size="md"
            radius="sm"
            variant="bordered"
            disableAnimation
            startContent={<MailIcon color="text-primary-400" />}
            onPress={() => onRedirect("SMTP")}
          >
            Connect other service (Yahoo, etc.)
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400 ">
          By connecting your account, you authorize us to send emails on your
          behalf. Rest assured, your
          <Link
            className="text-sm"
            isExternal
            showAnchorIcon
            href="https://loadconnect.io/PrivacyPolicy"
          >
            &nbsp;privacy
          </Link>{" "}
          and
          <Link
            className="text-sm"
            isExternal
            showAnchorIcon
            href="https://loadconnect.io/TOC"
          >
            &nbsp;terms
          </Link>{" "}
          are respected.
        </div>
      </ModalFooter>
    </>
  );
};

export default MainService;
