import { CheckOneIcon } from "@/components/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";

const SaveModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: any }) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      size="2xl"
      classNames={{
        closeButton: "bg-gray-100 text-primary",
      }}
    >
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody className="flex flex-row gap-4 items-center px-6">
          <div className="m-4">
            <CheckOneIcon />
          </div>
          <div>
            <h1 className="font-semibold text-xl text-black">
              This load successfully saved to your load manager
            </h1>
            <p>Tips: We will notify you once this load is available again</p>
          </div>
        </ModalBody>
        <ModalFooter className="mb-2 px-6">
          <Button
            className="w-full data-[pressed=true]:scale-100"
            color="primary"
            onPress={onClose}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SaveModal;
