import { FC } from "react";
import { PercentageStatus } from "@/components/percentage-status";
import { toClockTimeBySeconds } from "@/utils/toClockTime";
import {
  LOAD_BOARDS_LOGO_MAP,
  LOAD_BOARDS_NAME_MAP,
  LOAD_BOARDS_NAME_REGEX,
} from "@/constants/loadboards";

interface Props {
  data: {
    source: string;
    percentage: string;
    total: string;
    diffPercentage: string;
    up: boolean;
    wasted_time: string;
  };
}

export const LoadboardPerformanceItem: FC<Props> = ({ data }: any) => {
  const source = data.loadboard;
  const percentage = data.percentage;
  const diffPercentage = data.inquiries.percentage;
  const total = data.inquiries.total;
  const up = data.inquiries.up;
  const wasted_time = data.totalTime;

  const loadboard =
    source.match(LOAD_BOARDS_NAME_REGEX)?.[0].toLowerCase() || "";

  const logo = LOAD_BOARDS_LOGO_MAP[loadboard];
  const name = LOAD_BOARDS_NAME_MAP[loadboard];

  return (
    <div className="grid grid-cols-[24px_1fr] gap-4 border-b pb-2 relative last:border-b-0 last:pb-0">
      {logo ? (
        <img src={logo} alt="logo" className="w-6 h-6" />
      ) : (
        <p>{loadboard}</p>
      )}
      <div className="flex flex-col">
        <h4 className="text-sm">{name}</h4>
        <p>
          Totally inquiry initiated
          <span className="font-medium ml-2 text-gray-950">{total}</span>
        </p>
        <p className="flex items-baseline gap-1">
          {diffPercentage === "N/A" ? (
            diffPercentage
          ) : (
            <>
              <PercentageStatus value={diffPercentage} isUp={up} /> vs last
              period
            </>
          )}
        </p>
      </div>
      <span className="absolute top-0 right-0 flex gap-2 items-center">
        <span className="text-gray-950">
          {toClockTimeBySeconds(+wasted_time)}
        </span>
        {percentage}
      </span>
    </div>
  );
};
