import {
  getUserOrganization,
  getUserOrganizationMembers,
  postAddOrganizationEmails,
  postOrganizationName,
} from "@/server/myTeam.service";
import { sendInvitationToken } from "@/server/myTeam.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const setUserOrganisationName = createAsyncThunk(
  "myTeam/setOrganizationName",
  async (organizationName: string) => {
    return await postOrganizationName(organizationName);
  }
);

export const setUserOrganisationMembers = createAsyncThunk(
  "myTeam/setUserOrganisationMembers",
  async (emails: string[]) => {
    return await postAddOrganizationEmails(emails);
  }
);

export const getUserOrganisationInfo = createAsyncThunk(
  "myTeam/getOrganization",
  async () => {
    return await getUserOrganization();
  }
);

export const getUserOrganizationMembersList = createAsyncThunk(
  "myTeam/getOrganizationMembers",
  async () => {
    return await getUserOrganizationMembers();
  }
);

export const sendUserInvitationToken = createAsyncThunk(
  "myTeam/sendUserInvitationToken",
  async (token: string) => {
    return await sendInvitationToken(token);
  }
);
