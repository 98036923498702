import { ReactComponent as LightningEmoji } from "@/assets/svg/lightning-emoji.svg";
import { Button, Link } from "@nextui-org/react";
import { CONTACT_US_LINK } from "@/constants/links";

export const LockedView = () => {
  return (
    <div className="w-full h-full backdrop-blur-xl z-10 -m-10 flex flex-col justify-center items-center gap-6">
      <LightningEmoji />
      <div className="flex flex-col gap-2 items-center max-w-[400px]">
        <h3 className="text-gray-800 text-center">
          Unlock Team Management for Your Dispatching Success
        </h3>
        <p className="text-gray-800">
          Fill out the form below, and our team will get in touch to help you
          unlock this feature
        </p>
      </div>
      <Button as={Link} href={CONTACT_US_LINK} target="_blank" color="primary">
        Contact Us
      </Button>
    </div>
  );
};
